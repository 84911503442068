export const LABELS = {
    DATE_CREATED: 'Created At',
    DATE_UPDATED: 'Updated At',
    TOTAL_VALUE: 'Share Price',
    BASE_VALUATION: 'Is the Base Valuation',
    VALUATION_COUNT: 'Valuation Count',
    CREATE_VALUATION_EVENT: 'Create Valuation Event',
    ARE_YOU_SURE: 'Are you sure you would like to update the valuation?'
  }

const NOTIFICATIONS = {
    DAO_ID_MISSING: 'DAO ID not provided',
    SUCCESSFULLY_CREATED: "Valuation Successfully Created"
  }
  
  const BUTTONS = {
    CREATE_VALUATION: 'Create Valuation',
    CREATE_ALLOCATION: '*Create company allocation',
  }
  
  const VALUATION_VIEW_LABELS = {
    NOTIFICATIONS,
    BUTTONS,
    LABELS
  }
  
  
  export default VALUATION_VIEW_LABELS;