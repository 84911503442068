import { getWholeChar } from 'utils';
import { AvatarBuilderProps } from './AvatarBuilder.props';
import { useMemo } from 'react';
import { AvatarSize } from 'types/enums';
import { AnimatedAvatar } from './AvatarBuilder.style';

const AvatarBuilder = ({
  src,
  alt,
  id,
  size,
  onClick,
  style,
  hideAnimation = false,
  isSquare = false
}: AvatarBuilderProps): React.ReactElement => {
  // Value passed as alt tag will be the initial default using passed child default in Mui avatar, secondary default is genereic personIcon provided by Mui
  const iconSize = useMemo(() => {
    switch (size) {
      case AvatarSize.XX_LARGE:
        return {
          width: '10rem',
          height: '10rem',
          fontSize: '2rem',
          cursor: onClick && 'pointer'
        };
      case AvatarSize.X_LARGE:
        return {
          width: '6.25rem',
          height: '6.25rem',
          fontSize: '2rem',
          cursor: onClick && 'pointer'
        };
      case AvatarSize.LARGE:
        return {
          width: '3rem',
          height: '3rem',
          fontSize: '2rem',
          cursor: onClick && 'pointer'
        };
      case AvatarSize.MEDIUM:
        return {
          width: '2rem',
          height: '2rem',
          fontSize: '1rem',
          cursor: onClick && 'pointer'
        };
      case AvatarSize.SMALL:
        return {
          width: '1.5rem',
          height: '1.5rem',
          fontSize: '.75rem',
          cursor: onClick && 'pointer'
        };
      default:
        return {}; // fallback to empty object for unknown size
    }
  }, [size, onClick]);

  return (
    <AnimatedAvatar
      src={src}
      id={id}
      data-testid={id}
      alt={alt}
      onClick={onClick && onClick}
      style={{ ...iconSize, ...style }}
      hideAnimation={hideAnimation}
      isSquare={isSquare}
    >
      {alt && getWholeChar(alt, 0).toUpperCase()}
    </AnimatedAvatar>
  );
};

export default AvatarBuilder;
