import { BaseQueryParams } from "@piefi-platform/types-lib";
import { AxiosResponse } from "axios";
import { useCallback } from "react";
import CompanyAllocation from "../../../types/models/company-allocation.model";
import useHttp from "../../use-http";
import { companyUrl } from "../company-service/use-dao-service.url";
import CompanyAllocationService from "./use-company-allocation-service.model";
import CompanyAllocationDto from "@piefi-platform/types-lib/dtos/company-allocation.dto";

const useCompanyAllocationService = (): CompanyAllocationService => {
  const {  post, get, put } = useHttp();

  const createCompanyAllocation = useCallback(
    (
      companyId: string,
      companyAllocation: Pick<CompanyAllocation, 'shareCount'>
    ): Promise<AxiosResponse<{}>> => {
      return post<{}>(
        `${companyUrl}/${companyId}/company-allocations`,
        companyAllocation
      );
    },
    [post]
  );

  const updateCompanyAllocation = useCallback(
    (
      companyId: string,
      companyAllocationId: string,
      companyAllocation: Pick<CompanyAllocation, 'shareCount'>
    ): Promise<AxiosResponse<CompanyAllocationDto>> => {
      return put<CompanyAllocationDto>(
        `${companyUrl}/${companyId}/company-allocations/${companyAllocationId}`,
        companyAllocation as CompanyAllocationDto
      );
    },
    [put]
  );

  const getPageOfCompanyAllocations = useCallback(
    (
      companyId: string,
      { page, size }: BaseQueryParams
    ): Promise<AxiosResponse<CompanyAllocation[]>> => {
      return get<CompanyAllocation[]>(
        `${companyUrl}/${companyId}/company-allocations?size=${size}&page=${page}`
      );
    },
    [get]
  );

  const getCompanyAllocationById = useCallback(
    (
      companyId: string,
      companyAllocationId: string,
    ): Promise<AxiosResponse<CompanyAllocationDto>> => {
      return get<CompanyAllocationDto>(
        `${companyUrl}/${companyId}/company-allocations/${companyAllocationId}`
      );
    },
    [get]
  );

  return {
    createCompanyAllocation,
    getPageOfCompanyAllocations,
    updateCompanyAllocation,
    getCompanyAllocationById
  };
};

export default useCompanyAllocationService;
