import { ROUTES } from "constants/routes";
import React from "react";
import { useNavigate, useParams } from "react-router";
import DocumentCreateForm from "./DocumentCreateForm";

const DocumentCreate = (): React.ReactElement => {
  const navigate = useNavigate();
  let { daoId } = useParams();

  const onCreate = () => {
    daoId && navigate(`${ROUTES.DAOS_DOCUMENTS(daoId)}`);
  };

  if (!daoId) return <></>;

  return <DocumentCreateForm daoId={daoId} onCreate={onCreate} />;
};

export default DocumentCreate;
