import { styled } from "@mui/material";
import { Box } from "@mui/system";

const WizardStepStyle = styled(Box)(() => ({
  height: 50, 
  width: 50,
  borderRadius: 150,
  border: '1px solid black',
  '&:hover': {
    cursor: 'pointer'
  }
}))

export default WizardStepStyle;