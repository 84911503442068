import { Auth, EmailAuthProvider } from 'firebase/auth';
import { FirebaseAuthProvider } from '../../enums';

export default interface AuthContextType {
  auth: Auth;
  token: string;
  firebaseUserId: string;
  firebaseAuthProvider: FirebaseAuthProvider | undefined;
  setFirebaseAuthProvider: (provider: FirebaseAuthProvider | undefined) => void;
  logOut: () => void;
}

export const initAuthContextType = (auth = {} as Auth): AuthContextType => ({
  auth,
  token: '',
  firebaseUserId: '',
  firebaseAuthProvider: EmailAuthProvider.PROVIDER_ID as FirebaseAuthProvider,
  setFirebaseAuthProvider: (provider: FirebaseAuthProvider | undefined) => {},
  logOut: () => {}
});