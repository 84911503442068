import {
  Button,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DocumentDTO } from "@piefi-platform/types-lib";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { DOCUMENT_LABELS } from "../../constants/document.labels";
import { ROUTES } from "../../constants/routes";
import useDaoDocumentService from "../../hooks/services/dao-document/use-document-service";
import useDao from "../../hooks/use-dao";
import DateFormatter from "../labels/date-formatter/DateFormatter";
import { ConfirmationModal } from "../modals";

const DaoDocumentManager = () => {
  const { currentDao } = useDao();
  const { getDocuments, deleteDocument } = useDaoDocumentService();
  const navigate = useNavigate();
  const [documents, setDocuments] = useState<DocumentDTO[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [selectedDocId, setSelectedDocId] = useState<string | null>(null);
  const [canLoadMore, setCanLoadMore] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuDocId, setMenuDocId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    docId: string
  ) => {
    setAnchorEl(event.currentTarget);
    setMenuDocId(docId);
  };

  const handleClose = (docId: string) => {
    setAnchorEl(null);
    setMenuDocId(null);
  };

  const { LABELS, BUTTONS } = DOCUMENT_LABELS;

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      if (!currentDao) return;
      const params = { page, size: rowsPerPage };
      const res = await getDocuments(currentDao.id, params);
      setDocuments(res.data);
      setCanLoadMore(res.data.length === rowsPerPage);
    } catch (error) {
      return;
    } finally {
      setLoading(false);
    }
  }, [currentDao, getDocuments, page, rowsPerPage]);

  const deleteDoc = useCallback(async () => {
    if (!currentDao || !selectedDocId) return;
    await deleteDocument(currentDao.id, selectedDocId);
    setConfirmationModalOpen(false);
    fetchData();
  }, [currentDao, deleteDocument, fetchData, selectedDocId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const openUrlInNewTab = useCallback((url: string) => {
    window.open(url, "_blank");
  }, []);

  const handleDeleteClick = (docId: string) => {
    setSelectedDocId(docId);
    setConfirmationModalOpen(true);
  };

  return (
    <>
      {!loading && (
        <Stack>
          <Button
            variant="contained"
            onClick={() => navigate(ROUTES.DAOS_DOCUMENTS_CREATE(currentDao?.id))}
            sx={{ margin: "1rem auto 2rem 0" }}
          >
            {BUTTONS.CREATE_DOCUMENT}
          </Button>
          {documents.length > 0 && (
            <TableContainer component={Paper} sx={{ maxHeight: "30rem" }}>
              <Table stickyHeader aria-label="documents table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{LABELS.FILE_NAME}</TableCell>
                    <TableCell align="center">{LABELS.DOCUMENT_ID}</TableCell>
                    <TableCell align="center">{LABELS.CREATED_AT}</TableCell>
                    <TableCell align="center">{LABELS.REQUIRED}</TableCell>
                    <TableCell align="center">{LABELS.SIGNED}</TableCell>
                    <TableCell align="center">{LABELS.ACTIONS}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documents.map((doc) => (
                    <TableRow key={doc.id}>
                      <TableCell component="th" scope="row" align="center">
                        {doc.fileName}
                      </TableCell>
                      <TableCell align="center">{doc.id}</TableCell>
                      <TableCell align="center">
                        <DateFormatter date={doc.createdAt} />
                      </TableCell>
                      <TableCell align="center">
                        {doc.required ? "Yes" : "No"}
                      </TableCell>
                      <TableCell align="center">
                        {doc.documentSignatures.length > 0
                          ? "Signed"
                          : "Not Signed"}
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          aria-label="more"
                          id={`button-${doc.id}`}
                          aria-controls={
                            anchorEl ? "document-actions-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={anchorEl ? "true" : undefined}
                          onClick={(event) => handleClick(event, doc.id)}
                        >
                          {BUTTONS.CHOOSE_ACTION}
                        </Button>
                        <Menu
                          id="document-actions-menu"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl) && menuDocId === doc.id}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": `button-${doc.id}`,
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              handleClose(doc.id);
                              navigate(
                                ROUTES.DAOS_DOCUMENT_VIEW(
                                  currentDao?.id,
                                  doc.id
                                )
                              );
                            }}
                          >
                            {BUTTONS.VIEW_DOCUMENT}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleClose(doc.id);
                              openUrlInNewTab(doc.fileUrl);
                            }}
                          >
                            {BUTTONS.VIEW_FILE}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleClose(doc.id);
                              handleDeleteClick(doc.id);
                            }}
                          >
                            {BUTTONS.DELETE_DOCUMENT}
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {page !== 0 && (
            <Button
              onClick={() => setPage((prevPage) => prevPage - 1)}
              disabled={page === 0}
            >
              {BUTTONS.PREVIOUS}
            </Button>
          )}
          {canLoadMore && (
            <Button
              onClick={() => setPage((prevPage) => prevPage + 1)}
              disabled={!canLoadMore}
            >
              {BUTTONS.NEXT}
            </Button>
          )}
          {documents.length === 0 && (
            <Typography variant={"body1"}>{LABELS.NO_DOCUMENTS}</Typography>
          )}
          <ConfirmationModal
            onCancel={() => setConfirmationModalOpen(false)}
            onConfirm={deleteDoc}
            open={confirmationModalOpen}
            title={LABELS.ARE_YOU_SURE}
            subtitle={LABELS.ARE_YOU_SURE_DELETE}
          />
        </Stack>
      )}
    </>
  );
};

export default DaoDocumentManager;
