const LABELS = {
    CURRENT_SEASON: 'Current Season',
    ID: 'Id',
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    SEASON_NUMBER: 'Season Number',
}

const NOTIFICATIONS = {
    DAO_ID_MISSING: 'DAO ID not provided',
    SUCCESSFULLY_Ended: "Season Successfully Ended"
}
  
const BUTTONS = {
    END_SEASON: 'End Season',
}


export const SEASONS_VIEW_LABELS = { LABELS, NOTIFICATIONS, BUTTONS}