import { Drawer, List, ListItem, Typography } from "@mui/material";
import { LeftNavProps } from "./LeftNav.props";

const LeftNav = ({ options, activeIndex, onClick }: LeftNavProps): React.ReactElement => {
  const drawerWidth = 240;

  return (
    <Drawer
      variant="permanent"
      style={{ width: drawerWidth }}
      PaperProps={{ style: { width: "240px", marginTop: "70px" } }}
    >
      <List>
        {options.map((option, index) => (
          <ListItem
            button
            key={option.label}
            onClick={() => onClick(index, option.url)}
            sx={{
              padding: "1rem 1rem",
              backgroundColor:
                index === activeIndex ? "action.hover" : "inherit",
              "&:hover": {
                backgroundColor: "action.hover",
              },
            }}
          >
            <Typography variant={"body1"}>{option.label}</Typography>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default LeftNav;
