// DocumentCreateForm.tsx
import { Button, Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { Document } from "@piefi-platform/types-lib";
import { ChangeEvent, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { DOCUMENT_LABELS } from "../../constants/document.labels";
import { useNotification } from "../../hooks";
import useDaoDocumentService from "../../hooks/services/dao-document/use-document-service";
import { Severity } from "../../types/enums";
import uploadFile from "../../utils/upload-file";
import InputForm from "../input-form/InputForm";

interface DocumentCreateFormProps {
  daoId: string;
  onCreate: () => void;
}

interface DocCreate extends Document {
  file?: File | null;
}

const DocumentCreateForm = ({ daoId, onCreate }: DocumentCreateFormProps) => {
  const [loading, setLoading] = useState(false);
  const { createDocument } = useDaoDocumentService();
  const [file, setFile] = useState<File>();
  const { control, handleSubmit } = useForm<DocCreate>({
    mode: "onSubmit",
    shouldFocusError: true,
    reValidateMode: "onChange",
    defaultValues: {
      fileName: "",
      required: false,
      fileUrl: "",
      file: null,
    },
  });

  const { notify } = useNotification();

  const submit: SubmitHandler<DocCreate> = async (document: DocCreate) => {
    try {
      setLoading(true);
      if (!daoId) return;
      if (!file) {
        notify("Please select a file", { severity: Severity.Error });
        return;
      }

      const fileUrl = await uploadFile(
        `daos/${daoId}/documents/${
          document.fileName
        }_${new Date().toISOString()}`,
        file
      );

      delete document.file;
      await createDocument(daoId, { ...document, fileUrl });

      onCreate();
      notify(DOCUMENT_LABELS.NOTIFICATIONS.DOCUMENT_CREATED);
    } catch (error: any) {
      notify(error.response.data.message || error.message, {
        severity: Severity.Error,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCaptureFile = (
    event: ChangeEvent<HTMLInputElement>,
    onChange: (...event: any[]) => void
  ) => {
    event.preventDefault();
    const { target } = event;
    if (target?.files?.length) {
      const file = target?.files[0];
      if (["application/pdf"].includes(file.type)) {
        const fileReader = new FileReader();
        setFile(file);
        fileReader.readAsDataURL(file);
        fileReader.onload = (e) => {
          onChange(e.target?.result as string);
        };
      } else {
        notify("Please select a valid image", { severity: Severity.Error });
      }
    }
  };

  return (
    <form data-testid="dao-form" onSubmit={handleSubmit(submit)}>
      <Typography variant={'h4'} padding={'2rem 0 1rem'}>{DOCUMENT_LABELS.FORM.CREATE_NEW_DOCUMENT}</Typography>
      <Stack spacing={1}>
        <Controller
          control={control}
          name="fileName"
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            return (
              <InputForm
                id={field.name}
                data-testid={field.name}
                {...field}
                autoFocus
                fullWidth
                hiddenLabel
                inputProps={{ maxLength: 128 }}
                error={!!fieldState.error}
                placeholder={DOCUMENT_LABELS.FORM.FILE_NAME}
                label={DOCUMENT_LABELS.FORM.FILE_NAME}
                size="small"
                type="text"
                variant="outlined"
                helperText={fieldState.error?.message}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="file"
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            return (
              <input
                accept="application/pdf"
                type="file"
                style={{ margin: "2rem 0 1rem" }}
                onChange={(e) => handleCaptureFile(e, field.onChange)}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="required"
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  onChange={(e) => field.onChange(e.target.checked)}
                  sx={{ marginLeft: '-0.5rem'}}
                />
              }
              label="Required"
            />
          )}
        />
      </Stack>
      <Button variant={'contained'} type={'submit'} sx={{ marginTop: '1.5rem' }} disabled={loading || !file}>
        {DOCUMENT_LABELS.FORM.CREATE}
      </Button>
    </form>
  );
};

export default DocumentCreateForm;
