import { DaoDTO, DaoUpdatePayload } from '@piefi-platform/types-lib';
import React, { createContext, ReactNode, useCallback, useState, useEffect } from 'react';
import { useParams } from "react-router";
import DaoContextType, { initDaoContextType } from 'types/models/contexts/dao-context.model';
import DAO_VIEW_LABELS from '../constants/dao-view.labels';
import { useDaoService, useNotification } from '../hooks';

const initialState: DaoContextType = initDaoContextType()

export const DaoContext = createContext(initialState);

export function DaoProvider({ children }: { children: ReactNode }): React.ReactElement {
  const [currentDao, setCurrentDao] = useState<DaoDTO>();
  const { daoId } = useParams()
  const {getDaoById,updateDaoById} = useDaoService();
  const {notify} = useNotification();
  const {NOTIFICATIONS} = DAO_VIEW_LABELS;
  
  useEffect(() => {
    if(!daoId) return;

    const getDao = async () => {
      const {data: dao} = await getDaoById(daoId)
      if (!dao?.id) {
        notify(NOTIFICATIONS.NOT_FOUND);
      }
      setCurrentDao({...dao});
    }

    getDao();
  }, [NOTIFICATIONS.NOT_FOUND, daoId, getDaoById, notify])

  const updateCurrentDao = useCallback((dao: DaoDTO) => {
    setCurrentDao(dao);
  }, []);

  const update = useCallback(async (daoId: string, payload: DaoUpdatePayload) => {
    const {data: dao} = await updateDaoById(daoId, payload);
    setCurrentDao({...dao})
    return dao;
  }, [updateDaoById])

  return (
    <DaoContext.Provider value={{currentDao, updateCurrentDao, update}}>
        {children}
    </DaoContext.Provider>
  );
};
