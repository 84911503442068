import { Season } from "@piefi-platform/types-lib";
import { AxiosResponse } from "axios";
import { useCallback } from "react";
import useHttp from "../../use-http";
import SeasonDTO from "@piefi-platform/types-lib/dtos/season.dto";
import SeasonService from "./use-season-service.model";
import { seasonsUrl, getCurrentSeasonUrl } from "./use-season-service.url";

const useSeasonService = (): SeasonService => {
  const { get, put } = useHttp();

  const getCurrentSeason = useCallback(
    (
      daoId: string,
    ): Promise<AxiosResponse<SeasonDTO[]>> => {
      return get<SeasonDTO[]>(
        `${getCurrentSeasonUrl(daoId)}`,
      );
    },
    [get]
  );

  const endCurrentSeason = useCallback(
    (
      daoId: string,
    ): Promise<AxiosResponse<Season>> => {
      return put<Season>(
        `${seasonsUrl(daoId)}`
      );
    },
    [put]
  );

  return {
    endCurrentSeason,
    getCurrentSeason,
  };
};

export default useSeasonService;
