export const ROUTES = {
  AUTH: '/auth',
  DASHBOARD: '/',  
  NOT_FOUND: '404',
  USERS: '/users',
  USERPROFILE: '/users/:userId',
  COMPANIES_ROOT: '/companies',
  COMPANIES_VIEW: (companyId: string | undefined = ':companyId') => `/companies/${companyId}`,
  COMPANY_ALLOCATION_LIST: (companyId: string | undefined = ':companyId') => `/companies/${companyId}/company-allocations`,
  COMPANY_ALLOCATION_CREATE: (companyId: string | undefined = ":companyId") =>
  `/companies/${companyId}/company-allocations/-/`,
  COMPANY_ALLOCATION_UPDATE: (companyId: string | undefined = ":companyId", companyAllocationId: string | undefined = ":companyAllocationId") =>
  `/companies/${companyId}/company-allocations/${companyAllocationId}`,
  DAOS_CREATE: (companyId: string | undefined = ':companyId') => `/companies/${companyId}/daos`,
  DAOS_ROOT: ( daoId: string | undefined = ':daoId') => `/daos/${daoId}`,
  DAOS_VIEW: ( daoId: string | undefined = ':daoId') => `/daos/${daoId}`,
  DAOS_GENERAL_INFO: ( daoId: string | undefined = ':daoId') => `/daos/${daoId}/general`,
  DAOS_EQUITY_ALLOCATION: ( daoId: string | undefined = ':daoId') => `/daos/${daoId}/equity-allocations`,
  DAOS_SEASONS: ( daoId: string | undefined = ':daoId') => `/daos/${daoId}/seasons`,
  DAOS_TEMP_INVITATIONS: ( daoId: string | undefined = ':daoId') => `/daos/${daoId}/temp-invitations`,
  DAOS_DOCUMENTS: ( daoId: string | undefined = ':daoId') => `/daos/${daoId}/documents`,
  DAOS_DOCUMENTS_CREATE: ( daoId: string | undefined = ':daoId') => `/daos/${daoId}/documents/-/`,
  DAOS_DOCUMENT_VIEW: ( daoId: string | undefined = ':daoId', documentId: string | undefined = ':documentId') => `/daos/${daoId}/documents/${documentId}`,
  DAOS_SEED_DATA: ( daoId: string | undefined = ':daoId') => `/daos/${daoId}/seed-data`,
  DAOS_UPDATE: (daoId: string | undefined = ':daoId') => `/daos/${daoId}/update`,
  CREATE_DAO: '/daos/create',
  VALUATION_CREATE: (companyId: string | undefined = ':companyId') => `/companies/${companyId}/valuations`,
  VALUATION_LIST: (companyId: string | undefined = ':companyId') => `/companies/${companyId}/valuations-list`,
  SEASON_END: (daoId: string | undefined = ":daoId") => `/daos/${daoId}/seasons`,
};
