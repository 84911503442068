const BUTTON_LABEL = {
  CREATE_COMPANY: 'Create Company'
}

const DASHBOARD_LABEL = {
  BUTTON_LABEL,
  SEARCH_PROMPT: 'What company are you looking for?',
}


export default DASHBOARD_LABEL;