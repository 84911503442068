import BaseModal from './base-modal/BaseModal';
import ConfirmationModal from './confirmation-modal/ConfirmationModal';
import {
  ActionsContainerStyled,
  CancelButtonStyled,
  ConfirmButtonStyled,
  ModalContentStyled,
  ModalStyled,
  TitleStyled,
  SubTitleStyled
} from './confirmation-modal/ConfirmationModal.style';

export default BaseModal;

export {
  ConfirmationModal,
  ActionsContainerStyled,
  CancelButtonStyled,
  ConfirmButtonStyled,
  ModalContentStyled,
  ModalStyled,
  TitleStyled,
  SubTitleStyled
};
