import { CircularProgress } from "@mui/material";
import CompanyAllocationDto from "@piefi-platform/types-lib/dtos/company-allocation.dto";
import { CompanyAllocationForm } from "components";
import { ROUTES } from "constants/routes";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useCompanyAllocationService, useNotification } from "../../hooks";

const CompanyAllocationUpdate = (): React.ReactElement => {
  const navigate = useNavigate();
  let { companyId, companyAllocationId } = useParams();
  const { getCompanyAllocationById } = useCompanyAllocationService();
  const [companyAllocation, setCompanyAllocation] = useState<
    CompanyAllocationDto | undefined
  >();
  const [loading, setLoading] = useState(false);
  const { notify } = useNotification();
  useEffect(() => {
    if (!companyId || !companyAllocationId) return;

    const getData = async () => {
      try {
        setLoading(true)
        const companyAllocation = await getCompanyAllocationById(
          companyId!,
          companyAllocationId!
        );
        setCompanyAllocation(companyAllocation.data);
      } catch (error: any) {
        notify(`Error fetching company allocation: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [companyId, companyAllocationId, getCompanyAllocationById, notify]);

  const onUpdate = () => {
    companyId && navigate(`${ROUTES.COMPANIES_VIEW(companyId)}`);
  };

  if (!companyId) return <></>;

  if (loading) return <CircularProgress />

  if(!loading && !companyAllocation) return <></>

  return (
    <CompanyAllocationForm
      companyId={companyId}
      onSubmit={onUpdate}
      companyAllocation={companyAllocation}
    />
  );
};

export default CompanyAllocationUpdate;
