import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { FirebaseError } from "firebase/app";
import { useCompanyAllocationService, useNotification } from "hooks";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Severity } from "types/enums";
import FirebaseAuthError, {
  formatFirebaseError,
} from "types/enums/firebase-auth-error.enum";
import CompanyAllocationFormProps from "./company-allocation-form.props";

import EQUITY_ALLOCATION from "constants/company-allocation.labels";
import { ConfirmationModal } from "../../modals";
import InputForm from "../InputForm";

const CompanyAllocationForm = ({
  companyId,
  onSubmit: onCreate,
  companyAllocation,
}: CompanyAllocationFormProps): React.ReactElement => {
  const [loading, setLoading] = useState(false);
  const { notify } = useNotification();
  const { createCompanyAllocation, updateCompanyAllocation } =
    useCompanyAllocationService();
  const { control, getValues } = useForm<{ shareCount: string }>({
    mode: "onSubmit",
    shouldFocusError: true,
    reValidateMode: "onChange",
    defaultValues: {
      shareCount: companyAllocation?.shareCount
        ? `${Intl.NumberFormat("en-US").format(
            parseFloat(`${companyAllocation.shareCount}`.replace(/,/g, ""))
          )}`
        : "0",
    },
  });
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { FORM, BUTTONS, LABELS } = EQUITY_ALLOCATION;

  const submit = async () => {
    setLoading(true);
    try {
      const allocation = getValues();

      const shareCount = parseInt(`${allocation.shareCount}`.replace(/,/g, ""));
      if (companyAllocation?.id) {
        await updateCompanyAllocation(companyId, companyAllocation.id, {
          shareCount,
        });
      } else {
        await createCompanyAllocation(companyId, { shareCount });
      }
      notify(FORM.CREATE_SUCCESS);
      onCreate();
    } catch (error) {
      console.log(error);
      notify(
        formatFirebaseError(
          (error as FirebaseError)?.code as FirebaseAuthError
        ),
        {
          severity: Severity.Error,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form data-testid="company-allocation-form">
        <Stack spacing={3} paddingTop={'2rem'} width={'50%'}>
          <Typography variant={"h4"}>
            {companyAllocation?.id ? FORM.UPDATE : FORM.CREATE}
          </Typography>
          <Controller
            control={control}
            name="shareCount"
            rules={{ required: false }}
            render={({ field, fieldState }) => {
              const handleNumberChange = (
                e: React.ChangeEvent<HTMLInputElement>
              ) => {
                const value = e.target.value;
                const formattedValue = value
                  ? Intl.NumberFormat("en-US").format(
                      parseFloat(value.replace(/,/g, ""))
                    )
                  : "";
                field.onChange(formattedValue);
              };

              return (
                <InputForm
                  id={field.name}
                  data-testid={field.name}
                  {...field}
                  autoFocus
                  fullWidth
                  hiddenLabel
                  inputProps={{ maxLength: 128 }}
                  error={!!fieldState.error}
                  label={FORM.VALUE_ALLOCATED}
                  placeholder={FORM.VALUE_ALLOCATED}
                  onChange={handleNumberChange}
                  size="medium"
                  type="text"
                  variant="outlined"
                  helperText={fieldState.error?.message}
                />
              );
            }}
          />
        </Stack>
        <Button
          variant="contained"
          type="button"
          onClick={() => setShowConfirmation(!showConfirmation)}
          disabled={loading}
          sx={{ marginTop: '2rem' }}
        >
          {companyAllocation?.id ? BUTTONS.UPDATE : BUTTONS.CREATE}
        </Button>
      </form>
      <ConfirmationModal
        onCancel={() => {
          setShowConfirmation(false);
        }}
        onConfirm={() => {
          submit();
          setShowConfirmation(false);
        }}
        open={showConfirmation}
        subtitle={
          companyAllocation?.id
            ? LABELS.UPDATE_CONFIRMATION
            : LABELS.CREATE_CONFIRMATION
        }
      />
    </>
  );
};

export default CompanyAllocationForm;
