import { BaseQueryParams, Document, DocumentDTO } from "@piefi-platform/types-lib";
import { AxiosResponse } from "axios";
import { useCallback } from "react";
import { generateQueryString } from "../../../utils";
import useHttp from "../../use-http";
import DaoDocumentService from "./use-document-service.model";
import { daoDocumentsUrl } from "./use-document-service.url";

const useDaoDocumentService = (): DaoDocumentService => {
  const { get, post, _delete } = useHttp();

  const getDocuments = useCallback(
    (
      daoId: string,
      params: BaseQueryParams
    ): Promise<AxiosResponse<DocumentDTO[]>> => {
      return get<DocumentDTO[]>(
        `${daoDocumentsUrl(daoId)}?${generateQueryString(params)}`,
      );
    },
    [get]
  );

  const getDocument = useCallback(
    (
      daoId: string,
      documentId: string
    ): Promise<AxiosResponse<DocumentDTO>> => {
      return get<DocumentDTO>(
        `${daoDocumentsUrl(daoId)}/${documentId}`,
      );
    },
    [get]
  );

  const createDocument = useCallback(
    (
      daoId: string,
      body: Document
    ): Promise<AxiosResponse<DocumentDTO>> => {
      return post<DocumentDTO>(
        `${daoDocumentsUrl(daoId)}`,
        body as any
      );
    },
    [post]
  );

  const deleteDocument = useCallback(
    (
      daoId: string,
      documentId: string
    ): Promise<AxiosResponse<void>> => {
      return _delete<void>(
        `${daoDocumentsUrl(daoId)}/${documentId}`
      );
    },
    [_delete]
  );

  return {
    getDocuments,
    createDocument,
    deleteDocument,
    getDocument
  };
};

export default useDaoDocumentService;
