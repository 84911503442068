const LABELS = {
    DOCUMENT_ID: 'Document ID',
    FILE_NAME: 'File Name',
    FILE_URL: 'File URL',
    ARE_YOU_SURE: 'Are you sure?',
    CREATED_AT: 'Created At',
    ACTIONS: 'Actions',
    SIGNED: 'Signed',
    FILEURL: 'File URL',
    SIGNER_NAME: 'Signer Name',
    DATE_SIGNED: 'Date Signed',
    REQUIRED: 'Required',
    NO_SIGNATURES: 'No signatures.',
    NO_DOCUMENTS: 'No documents available.',
    ARE_YOU_SURE_DELETE: 'Are you sure you want to delete the document? The document will continue to live in file storage but no longer be present to System admins, Collective admins or anyone on platform.This action cannot be undone.',
}

const NOTIFICATIONS = {
    DOCUMENT_CREATED: 'Document Created',
}
  
const BUTTONS = {
    VIEW_DOCUMENT: 'View Document',
    VIEW_FILE: 'View File',
    CREATE_DOCUMENT: 'Create Document',
    DELETE_DOCUMENT: 'Delete Document',
    CHOOSE_ACTION: 'Choose an action',
    PREVIOUS: 'Previous',
    NEXT: 'Next',
}

const FORM = {
    CREATE_NEW_DOCUMENT: 'Create New Document',
    FILE_NAME: 'File Name',
    REQUIRED: 'Required',
    FILE: 'File',
    CREATE: 'Create',
}


export const DOCUMENT_LABELS = { LABELS, NOTIFICATIONS, BUTTONS, FORM}