import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function UpsideDaoIcon(props: SvgIconProps): React.ReactElement {
  const width = props.width ? `${props.width}px` : '30px';
  const height = props.height ? `${props.height}px` : '28px';

  return (
    <SvgIcon style={{ width, height }} viewBox="0 0 30 28" {...props}>
      <path
        d="M27.6444 19.2265C26.3428 18.4602 24.9071 18.7742 22.9694 16.4996C22.7346 16.2241 21.8687 14.7847 21.1208 13.4593C20.3634 12.1397 19.5682 10.6585 19.4503 10.3146C18.4775 7.47474 19.4636 6.36964 19.4603 4.84271C19.4553 2.39986 17.3952 0.370361 15.0045 0.370361C12.6239 0.370361 10.5538 2.39986 10.5488 4.84215C10.546 6.36907 11.5315 7.47417 10.5588 10.314C10.4408 10.6579 9.6456 12.1391 8.88823 13.4588C8.14087 14.7841 7.27443 16.2235 7.0396 16.4991C5.10193 18.7742 3.6662 18.4602 2.36459 19.226C0.282793 20.4519 -0.439521 23.2409 0.776392 25.3777C1.96893 27.4733 4.73353 28.2746 6.8209 27.0577C8.12529 26.297 8.57549 24.878 11.4859 24.3127C11.8381 24.2444 13.5003 24.2026 15.0051 24.2082C16.5098 24.2026 18.1715 24.2438 18.5243 24.3127C21.4347 24.878 21.8849 26.2965 23.1893 27.0577C25.2761 28.2746 28.0618 27.4371 29.2338 25.3777C30.4263 23.2821 29.7268 20.4519 27.6444 19.2265ZM19.5671 22.0714H10.4419C8.65451 22.0714 7.53765 20.108 8.43136 18.537L12.994 10.5179C13.8877 8.94689 16.1219 8.94689 17.0156 10.5179L21.5782 18.537C22.472 20.108 21.3545 22.0714 19.5671 22.0714Z"
        fill={props.fill || '#181818'}
      />
    </SvgIcon>
  );
}
