import { BaseQueryParams, CompanyCreatePayload, CompanyDTO } from '@piefi-platform/types-lib';
import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import useHttp from '../../use-http';
import CompanyService, { CompanyCreateResponse } from './use-company-service.model';
import { companyUrl } from './use-dao-service.url';

const useCompanyService = (): CompanyService => {
  const { post, get } = useHttp();

  const createCompany = useCallback(
    (company: CompanyCreatePayload): Promise<AxiosResponse<CompanyCreateResponse>> => {
      return post<CompanyCreateResponse>(`${companyUrl}`, company as any);
    },
    [post]
  );

  const getCompanyById = useCallback(
    (companyId: string): Promise<AxiosResponse<CompanyDTO>> => {
      return get<CompanyDTO>(`${companyUrl}/${companyId}`);
    },
    [get]
  );

  const getCompanies = useCallback(
    (params: BaseQueryParams): Promise<AxiosResponse<CompanyDTO[]>> => {
      return get<CompanyDTO[]>(`${companyUrl}?page=${params.page}&size=${params.size}${params.phrase ? `&phrase=${params.phrase}` : ''}`);
    },
    [get]
  );

  return {
    createCompany,
    getCompanyById,
    getCompanies
  };
};

export default useCompanyService;
