import { Box, Button, Modal, Stack, styled, Typography } from '@mui/material';

export const ModalContentStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '0.5rem',
  display: 'flex',
  height: '25.125rem',
  padding: '2rem',
  position: 'absolute',
  width: '31.1875rem',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));

export const ModalStyled = styled(Modal)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  padding: '2rem'
}));

export const TitleStyled = styled(Typography)(({ theme }) => ({
  color: 'gray',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '3em',
  lineHeight: '3.875rem'
}));

export const SubTitleStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '1rem',
  fontWeight: 700,
  lineHeight: '21px',
  marginBottom: '1rem',
  marginTop: '1rem'
}));

export const ActionsContainerStyled = styled(Stack)(({ theme }) => ({
  bottom: 0,
  position: 'absolute',
  right: 0
}));

const defaultButtonStyles = {
  minWidth: '5.4375rem',
  minHeight: '3rem',
};

export const CancelButtonStyled = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.grey[500] ?? ''}`,
  color: 'gray',
  '&:hover': {
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.common.white
  },
  ...defaultButtonStyles
}));

export const ConfirmButtonStyled = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.grey[900],
  color: theme.palette.common.white,
  marginLeft: '1rem',
  '&:hover': {
    border: `1px solid ${theme.palette.grey[500] ?? ''}`,
    backgroundColor: theme.palette.background.paper,
    color: 'gray'
  },
  ...defaultButtonStyles
}));
