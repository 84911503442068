import { Stack, Typography } from "@mui/material";
import { CompanyCardProps } from "./CompanyCard.props";
import AvatarBuilder from "components/avatar/AvatarBuilder";
import { AvatarSize } from "types/enums";
import { CompanyCardStyled } from "./CompanyCard.style";
import SubContent from "components/labels/SubContent";

const CompanyCard = ({
  title,
  subtitle,
  imageUrl,
  onClick,
}: CompanyCardProps): React.ReactElement => {
  return (
    <CompanyCardStyled
      onClick={onClick}
    >
      <AvatarBuilder
        src={imageUrl}
        id={title}
        alt={title}
        size={AvatarSize.XX_LARGE}
        isSquare
      />
      <Stack alignItems="center" justifyContent="center" marginTop={'0.25rem'}>
        <Typography variant={'body1'}>{title}</Typography>
        {subtitle && <SubContent variant={'body2'}>{subtitle}</SubContent>}
      </Stack>
    </CompanyCardStyled>
  );
};

export default CompanyCard;
