import { ValuationEventDTO } from '@piefi-platform/types-lib';
import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import useHttp from '../../use-http';
import ValuationService from './use-valuation-service.model';
import { getValuationsUrl, valuationUrl } from './use-valuation-service.url';

const useValuationService = (): ValuationService => {
  const { get, post } = useHttp();

  const getValuations = useCallback(
    (daoId: string, pageSize: number, page: number): Promise<AxiosResponse<ValuationEventDTO[]>> => {
        return get<ValuationEventDTO[]>(`${getValuationsUrl(daoId, pageSize, page)}`)
    },
    [get]
  );

  const createValuation = useCallback(
    (daoId: string, valuation: {sharePrice: number}): Promise<AxiosResponse<ValuationEventDTO>> => {
      return post<ValuationEventDTO>(`${valuationUrl(daoId)}`, valuation as any);
    },
    [post]
  );
  return {
    createValuation,
    getValuations
  };



};

export default useValuationService;
