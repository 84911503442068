import { CompanyAllocationForm } from "components";
import { ROUTES } from "constants/routes";
import React from "react";
import { useNavigate, useParams } from "react-router";

const CompanyAllocationCreate = (): React.ReactElement => {
  const navigate = useNavigate();
  let { companyId } = useParams();

  const onCreate = () => {
    companyId &&
      navigate(`${ROUTES.COMPANY_ALLOCATION_LIST(companyId)}`);
  };

  if (!companyId) return <></>;

  return <CompanyAllocationForm companyId={companyId} onSubmit={onCreate} />;
};

export default CompanyAllocationCreate;
