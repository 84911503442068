import { Button } from "@mui/material";
import { Stack } from "@mui/system";
import { CompanyCreatePayload } from "@piefi-platform/types-lib";
import { InputForm, UserAutoComplete } from "components";
import COMPANY_CREATE_FORM from "constants/company-create-form.labels";
import { FirebaseError } from "firebase/app";
import { useCompanyService, useNotification } from "hooks";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Severity } from "types/enums";
import FirebaseAuthError, { formatFirebaseError } from "types/enums/firebase-auth-error.enum";
import CompanyCreateFormProps from "./company-create-form.props";

const CompanyCreateForm = ({onCompanyCreate}: CompanyCreateFormProps): React.ReactElement => {
  const [companyCreatePayload, setCompanyCreatePayload] =
    useState<CompanyCreatePayload>({} as CompanyCreatePayload);
    const { createCompany } = useCompanyService();
  const { notify } = useNotification();
  const {
    control,
    handleSubmit,
    watch,
    trigger,
  } = useForm<CompanyCreatePayload>({
    mode: "onSubmit",
    shouldFocusError: true,
    reValidateMode: "onChange",
    defaultValues: {
      associatedUserId: "",
      company: {
        description: "",
        name: "",
      },
    },
  });

  useEffect(() => {
    // triggering validations
    const subscription = watch(
      (_, { type }) =>
        type === "change" && trigger(["company.description", "company.name"])
    );
    return () => subscription.unsubscribe();
  }, [watch, trigger]);

  const submit: SubmitHandler<CompanyCreatePayload> = async (
    company: CompanyCreatePayload
  ) => {
    try {
      const result = await createCompany({...company, ...companyCreatePayload});
      notify(COMPANY_CREATE_FORM.CREATE_SUCCESS)
      onCompanyCreate(result.data)
    } catch (error) {
      console.log(error)
      notify(
        formatFirebaseError(
          (error as FirebaseError)?.code as FirebaseAuthError
        ),
        {
          severity: Severity.Error,
        }
      );
    }
  };

  return (
    <form
      data-testid="company-form"
      onSubmit={handleSubmit(submit)}
    >
      <Stack spacing={3}>
        <h1>{COMPANY_CREATE_FORM.CREATE_COMPANY}</h1>
        <UserAutoComplete
          placeholder={COMPANY_CREATE_FORM.USER_AUTO_COMPLETE}
          userSelected={(user) =>
            user &&
            setCompanyCreatePayload({
              ...companyCreatePayload,
              associatedUserId: user?.id,
            })
          }
        />
        <Controller
          control={control}
          name="company.name"
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            return (
              <InputForm
                id={field.name}
                data-testid={field.name}
                {...field}
                autoFocus
                fullWidth
                hiddenLabel
                inputProps={{ maxLength: 128 }}
                error={!!fieldState.error}
                placeholder={COMPANY_CREATE_FORM.COMPANY_NAME}
                size="small"
                type="text"
                variant="outlined"
                helperText={fieldState.error?.message}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="company.description"
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            return (
              <InputForm
                id={field.name}
                data-testid={field.name}
                {...field}
                autoFocus
                fullWidth
                hiddenLabel
                inputProps={{ maxLength: 128 }}
                error={!!fieldState.error}
                placeholder={COMPANY_CREATE_FORM.COMPANY_DESCRIPTION}
                size="medium"
                type="text"
                variant="outlined"
                helperText={fieldState.error?.message}
              />
            );
          }}
        />
      </Stack>
      <Button type="submit">{COMPANY_CREATE_FORM.CREATE}</Button>
    </form>
  );
};

export default CompanyCreateForm;
