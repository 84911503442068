import { firebaseApp } from 'config';
import { ROUTES } from 'constants/routes';
import { Auth, getAuth, onIdTokenChanged, signOut } from 'firebase/auth';
import { useLocalStorage, useLocalStorageRef } from 'hooks';
import { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { FirebaseAuthProvider } from 'types/enums';
import { AuthContextType, initAuthContextType } from 'types/models';

const initialState: AuthContextType = initAuthContextType(getAuth(firebaseApp));

const AuthContext = createContext(initialState);

const AuthProvider = ({ children }: { children: ReactNode }): React.ReactElement => {
  const [auth] = useState<Auth>(initialState.auth);
  const [firebaseAuthProvider, setFirebaseAuthProvider] = useState<
    FirebaseAuthProvider | undefined
  >(initialState.firebaseAuthProvider);
  const [token, setToken] = useLocalStorage('token', '');
  const [firebaseUserId, setFirebaseUserId] = useLocalStorageRef('firebaseUserId', '');
  const navigate = useNavigate();

  useEffect(() => {
    const unregisterAuthObserver = onIdTokenChanged(auth, async (user) => {
      try {
        if (user) {
          setFirebaseUserId(user?.uid);
          setToken((await auth.currentUser?.getIdToken(false)) ?? '');
        }
      } catch {}
    });

    return () => unregisterAuthObserver();
  }, [auth, setToken, setFirebaseUserId]);

  const logOut = useCallback(async (): Promise<void> => {
    try {
      await signOut(auth);
      setToken('');
      setFirebaseUserId('');
      localStorage.clear();
      setFirebaseAuthProvider(undefined);
      navigate(ROUTES.AUTH);
    } catch (error) {
      throw error;
    }
  }, [auth, setToken, navigate, setFirebaseUserId]);

  return (
    <AuthContext.Provider
      value={{
        auth,
        token,
        firebaseUserId,
        firebaseAuthProvider,
        setFirebaseAuthProvider,
        logOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
export { AuthContext };

