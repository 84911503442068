import { CardContent, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Card, DateFormatter, SubContent } from "components";
import EQUITY_ALLOCATION from "constants/company-allocation.labels";
import { useEquityAllocationService, useNotification } from "hooks";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import EquityAllocationDTO from "types/models/equity-allocation.model";
import { numberFormatter } from "utils";

const EquityAllocationList = () => {
  const [equityAllocations, setEquityAllocations] = useState<
    EquityAllocationDTO[]
  >([]);
  const [loading, setLoading] = useState(true);
  let { daoId } = useParams();
  const { getPageOfEquityAllocations } = useEquityAllocationService();
  const { notify } = useNotification();
  const { NOTIFICATIONS, LABELS } = EQUITY_ALLOCATION;

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      if (!daoId) {
        notify(NOTIFICATIONS.DAO_ID_MISSING);
        return;
      }
      const results = await getPageOfEquityAllocations(daoId, {
        page: 0,
        size: 100,
      });
      setEquityAllocations(results.data);
    } catch (error) {
      console.error(error);
      notify(NOTIFICATIONS.ERROR);
      return;
    } finally {
      setLoading(false);
    }
  }, [
    NOTIFICATIONS.DAO_ID_MISSING,
    NOTIFICATIONS.ERROR,
    daoId,
    getPageOfEquityAllocations,
    notify,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {!loading && (
        <Stack>
          <Stack direction={"row"} spacing={2}>
            <Typography variant={"h6"}>
              {LABELS.EQUITY_ALLOCATION_COUNT}
            </Typography>
            <Typography variant={"h6"}>{equityAllocations.length}</Typography>
          </Stack>
          <Stack spacing={2} marginTop={"1rem"}>
            {equityAllocations.map((equityAllocation) => (
              <Card key={equityAllocation.id}>
                <CardContent>
                  <DateFormatter date={equityAllocation.createdAt} />
                  <SubContent>
                    {numberFormatter.formatterWithZeroDecimals(
                      equityAllocation?.bitCount || 0
                    )}{" "}
                    {LABELS.BITS}
                  </SubContent>
                </CardContent>
              </Card>
            ))}
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default EquityAllocationList;
