import { Box, styled } from "@mui/material";

export const CompanyCardStyled = styled(Box)(({ theme }) => ({
  padding: "0.5rem",
  alignItems: "center",
  border: `1px solid black`,
  borderRadius: "0.5rem",
  maxWidth: "10rem",
  minWidth: "10rem",
  width: "100%",
  justifyContent: "space-between",
  "&:hover, :focus-within": {
    cursor: "pointer",
    boxShadow: "0 0 0 0.2rem rgba(50, 50, 50, .15)",
  },
}));
