import { AxiosResponse } from "axios";
import useHttp from "../../use-http";
import DbSeedService from "./use-db-seed-service.model";
import { dbSeedUrl } from "./use-db-seed-service.url";
import { useCallback } from "react";
import { SeedDataResponse } from "components/generate-seed-data/GenerateSeedData.props";
import { DbSeedCreatePayload } from "@piefi-platform/types-lib";

const useDbSeedService = (): DbSeedService => {
  const {  post, _delete } = useHttp();

  const generateSeedData = useCallback(
    (
        daoId: string,
        data: DbSeedCreatePayload
    ): Promise<AxiosResponse<SeedDataResponse>> => {
      return post<SeedDataResponse>(`${dbSeedUrl(daoId)}`, data as SeedDataResponse);
    },
    [post]
  );

  const removeSeedData = useCallback(
    (
      daoId: string,
      ): Promise<AxiosResponse> => {
        return _delete<[]>(
        `${dbSeedUrl(daoId)}`
      );
    },
    [_delete]
  );

  return {
    generateSeedData,
    removeSeedData,
    };
};

export default useDbSeedService;
