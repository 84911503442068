import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import ErrorMessages from 'constants/error-messages';
import { HTTP_STATUS } from 'constants/http-statuses';
import { User } from 'firebase/auth';
import { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Severity } from 'types/enums';
import HttpResult from 'types/models/http-result.model';
import { useNotification } from '../hooks';
import useAuth from './use-auth';

const useHttp = (): HttpResult => {
  const http = useRef<AxiosInstance>({} as AxiosInstance);
  const { notify } = useNotification();
  const navigate = useNavigate();
  const { auth, logOut } = useAuth();

  /**
   * This method refreshes the token if this one will expire soon
   */
  const refreshToken = useCallback(async (): Promise<string> => {
    const currentUser: User | null = auth?.currentUser;

    if (!currentUser) {
      return '';
    }
    let { token, expirationTime } = await currentUser.getIdTokenResult();
    const expiredTime = new Date(expirationTime).getTime();
    const fiveMinutes = 300000;

    if (expiredTime - Date.now() < fiveMinutes) {
      // if the token will expire in the next 5 minutes,
      // forcefully grab a fresh token
      token = await currentUser.getIdToken(false);
    }
    return token;
  }, [auth]);

  useEffect(() => {
    const headers: Readonly<Record<string, string | boolean>> = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Origin': '*',
      crossDomain: true,
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('token') ?? '{}')}`
    };
    // `token` is gotten from the localStorage, it's set after sign in
    const injectToken = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig<any>> => {
      try {
        const token: string = await refreshToken();

        if (!token) {
          return config;
        }

        if (config.headers && token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      } catch (error) {
        throw new Error(error as string);
      }
    };
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers
    });

    axiosInstance.interceptors.request.use(injectToken, (error) => Promise.reject(error));

    axiosInstance.interceptors.response.use(
      (response) => response,
      async (error: any) => {
        console.log(error)
        if (
          error.response?.status === HTTP_STATUS.UNAUTHORIZED &&
          error?.response.data.message === ErrorMessages.INVALID_TOKEN
        ) {
          // trying to refresh the token when token has expired
          const token: string = await refreshToken();

          if (!token) {
            return error.config;
          }

          if (error.config?.headers && token) {
            error.config.headers['Authorization'] = `Bearer ${token}`;
          }
          return error.config;
        } else if (
          error.response?.status === HTTP_STATUS.BAD_REQUEST &&
          error?.response.data.message === ErrorMessages.USER_NOT_PROVIDED
        ) {
        } else if (
          error.response?.status === HTTP_STATUS.UNAUTHORIZED &&
          error?.response.data.message === ErrorMessages.UNAUTHORIZED
        ) {
          logOut();
        } else {
          // shows generic error to user
          notify(`${ error?.response.data.message ?? 'Error!'}`, {
            severity: Severity.Error
          });
        }
        return Promise.reject(error);
      }
    );

    http.current = axiosInstance;
  }, [notify, refreshToken, navigate, logOut]);

  const request = useCallback(<T, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> => {
    return http.current.request(config);
  }, []);

  const get = useCallback(
    <T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> => {
      return http.current.get<T, R>(url, config);
    },
    []
  );

  const post = useCallback(
    <T, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> => {
      return http.current.post<T, R>(url, data, config);
    },
    []
  );

  const put = useCallback(
    <T, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> => {
      return http.current.put<T, R>(url, data, config);
    },
    []
  );

  const patch = useCallback(
    <T, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> => {
      return http.current.patch<T, R>(url, data, config);
    },
    []
  );

  /**
   * this method starts with an underscore char due to
   * delete word is a reserved word in js
   */
  const _delete = useCallback(
    <T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> => {
      return http.current.delete<T, R>(url, config);
    },
    []
  );

  return { http: http.current, request, get, post, put, patch, _delete };
};

export default useHttp;
