import { Container, Stack } from "@mui/material";
import UpsideDaoIcon from "assets/icons/UpsideDaoIcon";
import { Outlet } from "react-router-dom";
import LogoOnlyLayoutProps from "./LogoOnlyLayout.props";
import { ContentStyled, RootStyled } from "./LogoOnlyLayout.style";

const LogoOnlyLayout = ({
  showIcon = true,
}: LogoOnlyLayoutProps): React.ReactElement => {
  return (
    <RootStyled title="SignIn | pieFi">
      <Container maxWidth="sm">
        <ContentStyled>
          {showIcon && (
            <Stack direction="column" alignItems="center" sx={{ mb: 3 }}>
              <UpsideDaoIcon
                viewBox="0 0 100 101"
                style={{ width: "3.5rem", height: "3.5rem" }}
              />
            </Stack>
          )}
          <Outlet />
        </ContentStyled>
      </Container>
    </RootStyled>
  );
};

export default LogoOnlyLayout;
