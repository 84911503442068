import {
  Button,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DbSeedCreatePayload } from "@piefi-platform/types-lib";
import { InputForm } from "components";
import BaseModal, { ConfirmationModal } from "components/modals";
import {
  ButtonLabels,
  CategoryLabels,
  DataOptionsLabels,
  DefaultActivityInputs,
  GeneralLabels,
} from "constants/generate-seed-data.labels";
import { useNotification } from "hooks";
import useDbSeedService from "hooks/services/db-seed-service/db-seed.service";
import { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Severity } from "types/enums";
import { SeedDataCategory } from "types/enums/generate-seed-data.enum";
import SeedDataPayload, { SeedDataResponse } from "./GenerateSeedData.props";

const GenerateSeedData = () => {
  const [selectedCategory, setSelectedCategory] = useState(
    SeedDataCategory.ECOMMERCE
  );
  const [checkedAll, setCheckedAll] = useState(false);
  const [postCount, setPostCount] = useState(1);
  const [proposalCount, setProposalCount] = useState(1);
  const [memberCount, setMemberCount] = useState(10);
  const [pointAwardEventCount, setPointAwardEventCount] = useState(1);
  const [activityCount, setActivityCount] = useState(
    DefaultActivityInputs.ECOMMERCE.length
  );
  const [activityInputs, setActivityInputs] = useState(
    DefaultActivityInputs.ECOMMERCE
  );
  const { generateSeedData, removeSeedData } = useDbSeedService();
  const { daoId } = useParams();
  const { notify } = useNotification();
  const [showConfirmationGenerate, setShowConfirmationGenerate] =
    useState(false);
  const [showConfirmationRemove, setShowConfirmationRemove] = useState(false);
  const [showGeneratedData, setShowGeneratedData] = useState(false);
  const [seedData, setSeedData] = useState<DbSeedCreatePayload | undefined>();
  const [seedDataResponse, setSeedDataResponse] = useState<
    SeedDataResponse | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [key, setKey] = useState(0);

  const { control, handleSubmit, reset, watch, getValues, setValue } =
    useForm<SeedDataPayload>({
      mode: "onSubmit",
      shouldFocusError: true,
      reValidateMode: "onChange",
      defaultValues: {
        category: SeedDataCategory.ECOMMERCE,
        formData: {
          postChecked: false,
          proposalChecked: false,
          memberChecked: false,
          pointAwardEventChecked: false,
          activityChecked: false,
          allChecked: false,
        },
        requestData: {
          postConfig: {
            postCount: 1,
          },
          proposalConfig: {
            proposalCount: 1,
          },
          memberConfig: {
            memberCount: 10,
          },
          pointAwardEventConfig: {
            pointAwardEventCount: 1,
          },
          activityConfig: {
            activityList: DefaultActivityInputs.ECOMMERCE,
          },
        },
      },
    });

  // Run whenever the category changes, updating the templated values
  useEffect(() => {
    const newCount = DefaultActivityInputs[selectedCategory].length;
    setActivityCount((prev) => newCount);
    setActivityInputs((prev) => [...DefaultActivityInputs[selectedCategory]]);
    reset({
      category: selectedCategory,
      formData: {
        postChecked: PostChecked,
        proposalChecked: ProposalChecked,
        memberChecked: MemberChecked,
        pointAwardEventChecked: PointAwardEventChecked,
        activityChecked: ActivityChecked,
        activityCount: newCount,
        allChecked: AllChecked,
      },
      requestData: {
        postConfig: {
          postCount,
        },
        proposalConfig: {
          proposalCount,
        },
        memberConfig: {
          memberCount,
        },
        pointAwardEventConfig: {
          pointAwardEventCount,
        },
        activityConfig: {
          activityList: DefaultActivityInputs[selectedCategory],
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  // set the new category when the user selects one
  const handleCategoryChange = (e: any) => {
    switch (e.target.value) {
      case CategoryLabels.ECOMMERCE:
        setSelectedCategory(SeedDataCategory.ECOMMERCE);
        break;
      case CategoryLabels.RESTAURANT:
        setSelectedCategory(SeedDataCategory.RESTAURANT);
        break;
      case CategoryLabels.SPORTS:
        setSelectedCategory(SeedDataCategory.SPORTS);
        break;
      case CategoryLabels.EMPLOYEES:
        setSelectedCategory(SeedDataCategory.EMPLOYEES);
        break;
      default:
        setActivityInputs([]);
    }
  };

  // used to force a rerender of the activityList field array when the category changes
  useEffect(() => {
    setKey((prevKey) => prevKey + 1); // increment key to force re-render
  }, [selectedCategory]);

  const submit: SubmitHandler<SeedDataPayload> = async () => {
    const activities = getValues(
      "requestData.activityConfig.activityList"
    ).slice(0, activityCount);

    const hydratedActivities = activities.map((activity, index) => {
      if(!activity?.activity) activity.activity = `Activity ${index + 1}`;
      if(!activity?.description) activity.description = '';
      if(!activity?.points) activity.points = { type: "FIXED", value: 0 };
      return activity;
    });

    const requestData: DbSeedCreatePayload = {
      postConfig: {
        postCount: PostChecked ? postCount : 0,
      },
      proposalConfig: {
        proposalCount: ProposalChecked ? proposalCount : 0,
      },
      memberConfig: {
        memberCount: MemberChecked ? memberCount : 0,
      },
      pointAwardEventConfig: {
        pointAwardEventCount: PointAwardEventChecked ? pointAwardEventCount : 0,
      },
      activityConfig: {
        activityList: ActivityChecked ? hydratedActivities : [],
      },
    };

    setSeedData(requestData);
  };

  const createSeedData = useCallback(async () => {
    try {
      setIsLoading(true);
      if (!daoId) throw new Error("No DAO ID");
      if (!seedData) throw new Error("No Seed Data");
      const { data } = await generateSeedData(daoId, seedData);
      setSeedDataResponse(data);
      notify("Seed Data Generated", { severity: Severity.Success });
    } catch (error: any) {
      console.error(error);
      notify(error?.response.data.message, { severity: Severity.Error });
    } finally {
      setIsLoading(false);
    }
  }, [daoId, seedData, generateSeedData, notify]);

  const removeSeededData = useCallback(async () => {
    try {
      setIsLoading(true);
      if (!daoId) throw new Error("No DAO ID");
      await removeSeedData(daoId);
      seedDataResponse && setSeedDataResponse(undefined);
      notify("Seed Data Removed", { severity: Severity.Success });
    } catch (error: any) {
      console.error(error);
      notify(error?.response.data.message, { severity: Severity.Error });
    } finally {
      setIsLoading(false);
      setShowConfirmationRemove(false);
    }
  }, [daoId, removeSeedData, seedDataResponse, notify]);

  const AllChecked = watch("formData.allChecked");
  const PostChecked = watch("formData.postChecked");
  const ProposalChecked = watch("formData.proposalChecked");
  const MemberChecked = watch("formData.memberChecked");
  const PointAwardEventChecked = watch("formData.pointAwardEventChecked");
  const ActivityChecked = watch("formData.activityChecked");

  return (
    <>
      <form data-testid="seed-data-form" onSubmit={handleSubmit(submit)}>
        <Stack spacing={2} padding={2} direction="column">
          <Stack spacing={2} direction="row" alignItems={"center"}>
            <InputLabel id="demo-simple-select-label">
              {GeneralLabels.CHOOSE_CATEGORY}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={CategoryLabels[selectedCategory]}
              label={GeneralLabels.CATEGORY}
              onChange={(event) => {
                handleCategoryChange(event);
              }}
            >
              {Object.values(CategoryLabels).map((label, index) => (
                <MenuItem key={index} value={label}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </Stack>

          <Stack spacing={2} padding={2} direction="column">
            <Stack spacing={2} direction="row">
              <Controller
                control={control}
                name="formData.allChecked"
                render={({ field }) => {
                  return (
                    <FormControlLabel
                      sx={{ width: "12.5rem" }}
                      control={
                        <Checkbox
                          id={field.name}
                          data-testid={field.name}
                          {...field}
                          autoFocus
                          inputProps={{ maxLength: 128 }}
                          size="medium"
                          checked={field.value}
                          onChange={(event) => {
                            field.onChange(event);
                            setValue(
                              "formData.postChecked",
                              event.target.checked
                            );
                            setValue(
                              "formData.proposalChecked",
                              event.target.checked
                            );
                            setValue(
                              "formData.memberChecked",
                              event.target.checked
                            );
                            setValue(
                              "formData.pointAwardEventChecked",
                              event.target.checked
                            );
                            setValue(
                              "formData.activityChecked",
                              event.target.checked
                            );
                            setCheckedAll(!checkedAll);
                          }}
                        />
                      }
                      label={DataOptionsLabels.ALL}
                    />
                  );
                }}
              />
            </Stack>

            <Stack spacing={2} direction="row">
              <Controller
                control={control}
                name="formData.memberChecked"
                render={({ field }) => {
                  return (
                    <FormControlLabel
                      sx={{ width: "12.5rem" }}
                      control={
                        <Checkbox
                          id={field.name}
                          data-testid={field.name}
                          {...field}
                          autoFocus
                          checked={field.value || checkedAll}
                          inputProps={{ maxLength: 128 }}
                          size="medium"
                          onChange={(event) => {
                            field.onChange(event);
                            if (checkedAll) setCheckedAll(false);
                            if (checkedAll)
                              setValue("formData.allChecked", false);
                            setValue("formData.postChecked", false);
                            setValue("formData.proposalChecked", false);
                            setValue("formData.pointAwardEventChecked", false);
                          }}
                        />
                      }
                      label={DataOptionsLabels.MEMBERS}
                    />
                  );
                }}
              />
              {MemberChecked && (
                <Controller
                  control={control}
                  name="requestData.memberConfig.memberCount"
                  defaultValue={10}
                  render={({ field, fieldState }) => {
                    return (
                      <InputForm
                        id={field.name}
                        data-testid={field.name}
                        {...field}
                        onChange={(event) => {
                          const value = Number(event.target.value);
                          field.onChange(value);
                          setMemberCount(value);
                        }}
                        autoFocus
                        fullWidth
                        hiddenLabel
                        inputProps={{ maxLength: 128 }}
                        error={!!fieldState.error}
                        size="small"
                        type="number"
                        variant="outlined"
                        helperText={fieldState.error?.message}
                      />
                    );
                  }}
                />
              )}
            </Stack>

            <Stack spacing={2} direction="row">
              <Controller
                control={control}
                name="formData.postChecked"
                render={({ field }) => {
                  return (
                    <FormControlLabel
                      sx={{ width: "12.5rem" }}
                      control={
                        <Checkbox
                          id={field.name}
                          data-testid={field.name}
                          {...field}
                          autoFocus
                          checked={field.value || checkedAll}
                          inputProps={{ maxLength: 128 }}
                          size="medium"
                          onChange={(event) => {
                            field.onChange(event);
                            if (checkedAll) setCheckedAll(false);
                            if (checkedAll)
                              setValue("formData.allChecked", false);
                          }}
                          disabled={!MemberChecked}
                        />
                      }
                      label={DataOptionsLabels.POSTS}
                    />
                  );
                }}
              />
              {PostChecked && (
                <Controller
                  control={control}
                  name="requestData.postConfig.postCount"
                  defaultValue={1}
                  render={({ field, fieldState }) => {
                    return (
                      <InputForm
                        id={field.name}
                        data-testid={field.name}
                        {...field}
                        onChange={(event) => {
                          const value = Number(event.target.value);
                          field.onChange(value);
                          setPostCount(value);
                        }}
                        autoFocus
                        fullWidth
                        hiddenLabel
                        inputProps={{ maxLength: 128 }}
                        error={!!fieldState.error}
                        size="small"
                        type="number"
                        variant="outlined"
                        helperText={fieldState.error?.message}
                      />
                    );
                  }}
                />
              )}
            </Stack>

            <Stack spacing={2} direction="row">
              <Controller
                control={control}
                name="formData.proposalChecked"
                render={({ field }) => {
                  return (
                    <FormControlLabel
                      sx={{ width: "12.5rem" }}
                      control={
                        <Checkbox
                          id={field.name}
                          data-testid={field.name}
                          {...field}
                          autoFocus
                          checked={field.value || checkedAll}
                          inputProps={{ maxLength: 128 }}
                          size="medium"
                          onChange={(event) => {
                            field.onChange(event);
                            if (checkedAll) setCheckedAll(false);
                            if (checkedAll)
                              setValue("formData.allChecked", false);
                          }}
                          disabled={!MemberChecked}
                        />
                      }
                      label={DataOptionsLabels.PROPOSALS}
                    />
                  );
                }}
              />
              {ProposalChecked && (
                <Controller
                  control={control}
                  name="requestData.proposalConfig.proposalCount"
                  defaultValue={1}
                  render={({ field, fieldState }) => {
                    return (
                      <InputForm
                        id={field.name}
                        data-testid={field.name}
                        {...field}
                        onChange={(event) => {
                          const value = Number(event.target.value);
                          field.onChange(value);
                          setProposalCount(value);
                        }}
                        autoFocus
                        fullWidth
                        hiddenLabel
                        inputProps={{ maxLength: 128 }}
                        error={!!fieldState.error}
                        size="small"
                        type="number"
                        variant="outlined"
                        helperText={fieldState.error?.message}
                      />
                    );
                  }}
                />
              )}
            </Stack>

            <Stack spacing={2} direction="row">
              <Controller
                control={control}
                name="formData.pointAwardEventChecked"
                render={({ field }) => {
                  return (
                    <FormControlLabel
                      sx={{ width: "12.5rem" }}
                      control={
                        <Checkbox
                          id={field.name}
                          data-testid={field.name}
                          {...field}
                          autoFocus
                          checked={field.value || checkedAll}
                          inputProps={{ maxLength: 128 }}
                          size="medium"
                          onChange={(event) => {
                            field.onChange(event);
                            if (checkedAll) setCheckedAll(false);
                            if (checkedAll)
                              setValue("formData.allChecked", false);
                          }}
                          disabled={!MemberChecked}
                        />
                      }
                      label={DataOptionsLabels.POINT_AWARD_EVENTS}
                    />
                  );
                }}
              />
              {PointAwardEventChecked && (
                <Controller
                  control={control}
                  name="requestData.pointAwardEventConfig.pointAwardEventCount"
                  defaultValue={100}
                  render={({ field, fieldState }) => {
                    return (
                      <InputForm
                        id={field.name}
                        data-testid={field.name}
                        {...field}
                        onChange={(event) => {
                          const value = Number(event.target.value);
                          field.onChange(event);
                          setPointAwardEventCount(value);
                        }}
                        autoFocus
                        fullWidth
                        hiddenLabel
                        inputProps={{ maxLength: 128 }}
                        error={!!fieldState.error}
                        size="small"
                        type="number"
                        variant="outlined"
                        helperText={fieldState.error?.message}
                      />
                    );
                  }}
                />
              )}
            </Stack>

            <Stack spacing={2} direction="row">
              <Controller
                control={control}
                name="formData.activityChecked"
                render={({ field }) => {
                  return (
                    <FormControlLabel
                      sx={{ width: "12.5rem" }}
                      control={
                        <Checkbox
                          id={field.name}
                          data-testid={field.name}
                          {...field}
                          autoFocus
                          checked={field.value || checkedAll}
                          inputProps={{ maxLength: 128 }}
                          size="medium"
                          onChange={(event) => {
                            field.onChange(event);
                            if (checkedAll) setCheckedAll(false);
                            if (checkedAll)
                              setValue("formData.allChecked", false);
                          }}
                        />
                      }
                      label={DataOptionsLabels.ACTIVITIES}
                    />
                  );
                }}
              />
              {ActivityChecked && (
                <Controller
                  control={control}
                  name="formData.activityCount"
                  render={({ field, fieldState }) => {
                    return (
                      <InputForm
                        id={field.name}
                        data-testid={field.name}
                        {...field}
                        value={activityCount}
                        // autoFocus
                        fullWidth
                        hiddenLabel
                        inputProps={{ maxLength: 128 }}
                        error={!!fieldState.error}
                        size="small"
                        type="number"
                        variant="outlined"
                        onChange={(event) => {
                          const value = Number(event.target.value);
                          field.onChange(event);
                          setActivityCount((prev) => value);
                        }}
                        helperText={fieldState.error?.message}
                      />
                    );
                  }}
                />
              )}
            </Stack>

            {ActivityChecked && activityCount > 0 && activityCount && (
              <Stack
                style={{
                  maxHeight: "25rem",
                  overflowY: "auto",
                  marginTop: "2rem",
                }}
              >
                {Array.from({ length: activityCount }).map((_, index) => (
                  <Controller
                    key={`${selectedCategory}-${index}-${key}`}
                    control={control}
                    name={
                      `requestData.activityConfig.activityList[${index}].activity` as any
                    }
                    defaultValue={
                      index >= activityInputs.length
                        ? ""
                        : activityInputs[index]?.activity
                    }
                    render={({ field, fieldState }) => {
                      return (
                        <InputForm
                          id={field.name}
                          data-testid={field.name}
                          {...field}
                          // value={index > activityInputs.length ? undefined : activityInputs[index]}
                          autoFocus
                          fullWidth
                          hiddenLabel
                          inputProps={{ maxLength: 128 }}
                          error={!!fieldState.error}
                          size="small"
                          type="text"
                          variant="outlined"
                          helperText={fieldState.error?.message}
                          sx={{ margin: "0.25rem 0" }}
                        />
                      );
                    }}
                  />
                ))}
              </Stack>
            )}
            <Stack
              spacing={2}
              direction="row"
              justifyContent={"flex-start"}
              marginTop={"3rem !important"}
            >
              <Button
                disabled={false}
                onClick={() => setShowConfirmationRemove(true)}
                variant={"outlined"}
              >
                {GeneralLabels.REMOVE}
              </Button>
              <Button
                type="submit"
                disabled={isLoading || !!seedDataResponse}
                variant={"contained"}
                onClick={() => setShowConfirmationGenerate(true)}
              >
                {GeneralLabels.GENERATE}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </form>
      <BaseModal
        title={GeneralLabels.DATA_CONFIRMATION}
        isOpen={showGeneratedData}
        close={() => setShowGeneratedData(false)}
      >
        {seedDataResponse && (
          <Stack>
            <Typography variant="h6">
              {GeneralLabels.DATA_GENERATED}:
            </Typography>
            <TextField
              multiline
              fullWidth
              variant={"outlined"}
              value={Object.entries(seedDataResponse)
                .map(([key, value]) => `${key}: ${value}`)
                .join("\n")}
              inputProps={{
                readOnly: true,
              }}
            />
          </Stack>
        )}
      </BaseModal>
      <ConfirmationModal
        onCancel={() => {
          setShowConfirmationGenerate(false);
        }}
        onConfirm={() => {
          createSeedData();
          setShowConfirmationGenerate(false);
          setShowGeneratedData(true);
        }}
        open={showConfirmationGenerate}
        subtitle={GeneralLabels.GENERATE_SUBTITLE}
        confirmText={ButtonLabels.GENERATE}
        cancelText={ButtonLabels.CLOSE}
        confirmDisabled={isLoading || !!seedDataResponse}
      />
      <ConfirmationModal
        onCancel={() => {
          setShowConfirmationRemove(false);
        }}
        onConfirm={() => {
          removeSeededData();
          setShowConfirmationRemove(false);
        }}
        open={showConfirmationRemove}
        subtitle={GeneralLabels.REMOVE_SUBTITLE}
        confirmText={ButtonLabels.REMOVE}
        cancelText={ButtonLabels.CLOSE}
      />
    </>
  );
};

export default GenerateSeedData;
