import { Toast } from "components";
import Router from "./routes";

function App(): React.ReactElement {
  return (
    <>
      <Toast />
      <Router />
    </>
  );
}

export default App;
