import { IconButton, Stack, Typography } from "@mui/material";
import CopyIcon from "assets/icons/CopyIcon";
import FlagIcon from "assets/icons/FlagIcon";
import { CLIPBOARD } from "constants/utils.labels";
import { useNotification } from "hooks";
import { copyToClipboard, formatDateLong } from "utils";
import CompanyHeaderProps from "./CompanyHeader.props";
import {
  CompanyNameStyled,
  ProfileContainerStyled,
  StyledHeaderStack,
  StyledInfoStack,
  SubStackStyled,
} from "./CompanyHeader.style";

const CompanyHeader = ({ company }: CompanyHeaderProps): React.ReactElement => {
  const { notify } = useNotification();
  const { COPIED } = CLIPBOARD;

  return (
    <ProfileContainerStyled data-testid="company-header" tabIndex={0}>
      <StyledHeaderStack>
        <StyledInfoStack id="profile-info-segment">
          <CompanyNameStyled data-testid="userName" variant="h1">
            {`Company: ${company.name}`}
          </CompanyNameStyled>
          <SubStackStyled direction={"row"} marginTop="0.5rem">
            {company.id !== undefined && (
              <>
                <IconButton
                  onClick={() => {
                    copyToClipboard(company.id);
                    notify(COPIED);
                  }}
                >
                  <CopyIcon />
                </IconButton>
                <Stack direction="row" alignItems="center">
                  <Typography variant="body2" color={"secondary.main"}>
                    {company.id}
                  </Typography>
                </Stack>
              </>
            )}
            {company.createdAt && (
              <Stack direction="row" alignItems="center" paddingLeft={"1rem"}>
                <FlagIcon />
                <Typography
                  variant="body2"
                  paddingLeft="0.25rem"
                  color={"secondary.main"}
                >
                  {formatDateLong(new Date(company.createdAt).toDateString())}
                </Typography>
              </Stack>
            )}
          </SubStackStyled>
        </StyledInfoStack>
      </StyledHeaderStack>
    </ProfileContainerStyled>
  );
};

export default CompanyHeader;
