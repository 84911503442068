import {
  BaseQueryParams,
  DaoMembershipDTO,
  FeedPostDTO,
  ManageableEntityDTO,
  RoomMembershipDTO,
  SearchResultDTO,
  User,
  UserEntityDTO,
  UserPostStatisticsDTO,
  UserProposalVoteStatisticsDTO,
  UserSimpleDTO,
  UserUpdate,
} from '@piefi-platform/types-lib';
import UserPointsStatisticsDTO from '@piefi-platform/types-lib/dtos/user-point-statistics.dto';
import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import useHttp from '../../use-http';
import UseUserService from './use-user-service.model';
import { userDaosUrl, userProfileUrl, userUrl } from './use-user-service.url';

const useUserService = (): UseUserService => {
  const { get, post, put } = useHttp();

  const getCurrentUser = useCallback((): Promise<AxiosResponse<UserEntityDTO>> => {
    return get<UserEntityDTO>(userUrl);
  }, [get]);

  const getUserById = useCallback(
    (userId: string): Promise<AxiosResponse<UserEntityDTO>> => {
      const url: string = `${userProfileUrl}/${userId}`;
      return get<UserEntityDTO>(url);
    },
    [get]
  );

  const getUsers = useCallback(
    (
      username: string,
      exactMatch: boolean,
      { page, size }: BaseQueryParams
    ): Promise<AxiosResponse<UserSimpleDTO[]>> => {
      const url: string = `${userProfileUrl}?username=${username}&exactMatch=${exactMatch}&page=${page}&size=${size}`;
      return get<UserSimpleDTO[]>(url);
    },
    [get]
  );

  const getUserDaoMemberships = useCallback(
    ({ page, size }: BaseQueryParams): Promise<AxiosResponse<DaoMembershipDTO[]>> => {
      return get<Array<DaoMembershipDTO>>(
        `${userDaosUrl}/dao-memberships?&page=${page}&size=${size}`
      );
    },
    [get]
  );

  const getUserRoomMemberships = useCallback(
    (
      daoId: string,
      { page, size }: BaseQueryParams
    ): Promise<AxiosResponse<RoomMembershipDTO[]>> => {
      return get<Array<RoomMembershipDTO>>(
        `${userDaosUrl}/${daoId}/rooms/room-memberships?&page=${page}&size=${size}`
      );
    },
    [get]
  );

  const getUserDaoPoints = useCallback(
    (
      userId: string,
      seasonId?: string,
      daoMembershipId?: string
    ): Promise<AxiosResponse<UserPointsStatisticsDTO>> => {
      return get<UserPointsStatisticsDTO>(
        `${userUrl}/${userId}/points-awards/?seasonId=${seasonId}&daoMembershipId=${daoMembershipId}`
      );
    },
    [get]
  );

  const getUserPostStatistics = useCallback(
    (userId: string): Promise<AxiosResponse<UserPostStatisticsDTO>> => {
      return get<UserPostStatisticsDTO>(`${userUrl}/${userId}/posts/statistics`);
    },
    [get]
  );

  const getUserProposalStatistics = useCallback(
    (userId: string): Promise<AxiosResponse<UserProposalVoteStatisticsDTO>> => {
      return get<UserProposalVoteStatisticsDTO>(
        `${userUrl}/${userId}/posts/proposals/proposal-votes/statistics`
      );
    },
    [get]
  );

  const getMetaFeedByUser = useCallback(
    (
      { page, size }: BaseQueryParams,
      type?: string
    ): Promise<AxiosResponse<Array<FeedPostDTO>>> => {
      return get<Array<FeedPostDTO>>(
        `${userUrl}/meta-feed?page=${page}&size=${size}${type ? `&type=${type}` : ''}`
      );
    },
    [get]
  );

  const getUserSearchResults = useCallback(
    (
      { page, size }: BaseQueryParams,
      phrase: string
    ): Promise<AxiosResponse<SearchResultDTO[]>> => {
      return get<SearchResultDTO[]>(`${userUrl}/search?page=${page}&size=${size}&phrase=${phrase}`);
    },
    [get]
  );

  const getUserJoinRequestsByDao = useCallback(
    (daoId: string): Promise<AxiosResponse<ManageableEntityDTO[]>> => {
      return get<ManageableEntityDTO[]>(
        `${userUrl}/manageable-entities/-/join-requests?page=0&size=100&daoId=${daoId}`
      );
    },
    [get]
  );

  const getUserPointsStatistics = useCallback(
    (
      userId: string,
      seasonId: string,
      daoMemberId: string,
      includeDaoAggregate: boolean
    ): Promise<AxiosResponse<UserPointsStatisticsDTO>> => {
      return get<UserPointsStatisticsDTO>(
        `${userUrl}/${userId}/points-awards?seasonId=${seasonId}&includeDaoAggregate=${includeDaoAggregate}&daoMembershipId=${daoMemberId}`
      );
    },
    [get]
  );

  const createUser = useCallback(
    (user: User): Promise<AxiosResponse<User>> => {
      return post<User>(`${userUrl}`, user);
    },
    [post]
  );

  const updateUser = useCallback(
    (user: UserUpdate): Promise<AxiosResponse<UserEntityDTO>> =>
      put<UserUpdate, AxiosResponse<UserEntityDTO>>(`${userUrl}`, user),
    [put]
  );

  return {
    getCurrentUser,
    getUserById,
    getUserDaoMemberships,
    getUserDaoPoints,
    getUserPostStatistics,
    getUserProposalStatistics,
    getUserRoomMemberships,
    getUsers,
    getMetaFeedByUser,
    getUserSearchResults,
    getUserJoinRequestsByDao,
    getUserPointsStatistics,
    createUser,
    updateUser,
  };
};

export default useUserService;
