import { Box, Grid, Stack, styled, Typography } from '@mui/material';

export const AvatarContainerStyled = styled(Grid)(({ theme }) => ({
  margin: '0.5rem 1rem 1rem 1rem',
  '& .MuiBadge-standard': {
    top: '5.125rem',
    marginRight: '2.3rem'
  },
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    justifyContent: 'center'
  },
  [theme.breakpoints.up('md')]: {
    margin: 0
  }
}));

export const StyledHeaderStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  margin: '1.25rem 2.25rem 0 0',
  width: 'unset',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }
}));

export const StyledInfoStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  width: '100%',
  alignItems: 'flex-start',
  [theme.breakpoints.down('md')]: {
    alignItems: 'center'
  }
}));

export const ProfileContainerStyled = styled(Box)(() => ({
  overflow: 'hidden',
  paddingBottom: '3rem',
  minHeight: 'unset'
}));

export const SegmentProfileInfoStyled = styled(Grid)(({ theme }) => ({
  margin: 'auto 1rem',
  [theme.breakpoints.up('md')]: {
    margin: 'auto',
    paddingLeft: '1rem'
  }
}));

export const StackStyled = styled(Stack)(({ theme }) => ({
  marginTop: '0.75rem',
  '& .MuiSvgIcon-root': {
    marginTop: '0.125rem'
  },
  '& .MuiPaper-root': {
    display: 'flex'
  },
  '& .MuiTypography-root': {
    color: theme.palette.grey[500],
    marginLeft: '-0.25rem'
  },
  '& .divider': {
    color: theme.palette.grey[500]
  }
}));

export const SubStackStyled = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  '& svg': {
    height: '1rem',
    width: '1rem'
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-start'
  },
  '& .MuiIconButton-root': {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.grey[300]
    }
  },
  '& path': {
    fill: theme.palette.grey[700]
  }
}));

export const UserAboutTextStyled = styled(Typography)(({ theme }) => ({
  fontWeight: 550,
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    textAlign: 'start',
    marginRight: '4.5rem'
  }
}));

export const CompanyNameStyled = styled(Typography)(({ theme }) => ({
  fontSize: '1.375rem',
  textAlign: 'center',
  marginLeft: '0',
  [theme.breakpoints.up('md')]: {
    textAlign: 'left'
  }
}));
