// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/charAt#getting_whole_characters
const getWholeChar = (str: string, i: number): string => {
    const code = str.charCodeAt(i);
  
    if (Number.isNaN(code)) return '';
  
    if (code < 0xd800 || code > 0xdfff) return str.charAt(i);
  
    if (0xd800 <= code && code <= 0xdbff) {
      if (str.length <= i + 1) {
        throw new Error('High surrogate without following low surrogate');
      }
  
      const next = str.charCodeAt(i + 1);
  
      if (0xdc00 > next || next > 0xdfff) {
        throw new Error('High surrogate without following low surrogate');
      }
  
      return str.charAt(i) + str.charAt(i + 1);
    }
  
    if (i === 0) {
      throw new Error('Low surrogate without preceding high surrogate');
    }
  
    const prev = str.charCodeAt(i - 1);
  
    if (0xd800 > prev || prev > 0xdbff) {
      throw new Error('Low surrogate without preceding high surrogate');
    }
  
    return '';
  };
  
  export default getWholeChar;
  