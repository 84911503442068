import {Operator, SeedDataCategory, SeedDataOperatorType} from "types/enums/generate-seed-data.enum";
import { Activity } from "types/models/generate-seed-data.model";

type defaultActivityInputs = {
    [key in SeedDataCategory]: Activity[];
};
  
const DefaultActivityInputs: defaultActivityInputs = {
    ECOMMERCE: [
        {
            activity: "% of purchase",
            description: "Owners Club members earn 15% of every purchase in the form of equity",
            points: {
                type: SeedDataOperatorType.VARIABLE,
                value: 0.15,
                operator: Operator.MULTIPLY
            }
        }, 
        {
            activity: "No discount",
            description: "Pay full price and get the discount in the form of equity instead",
            points: {
                type: SeedDataOperatorType.VARIABLE,
                value: 0.20,
                operator: Operator.MULTIPLY
            }
        }, 
        {
            activity: "Owners Club Membership",
            description: "Join the Owners Club and earn $200 of equity ownership",
            points: {
                type: SeedDataOperatorType.FIXED,
                value: 200
            }
        }, 
        {
            activity: "Refer a friend",
            description: "Refer a friend with your link and earn 5% of everything they purchase in the form of equity",
            points: {
                type: SeedDataOperatorType.VARIABLE,
                value: 0.05,
                operator: Operator.MULTIPLY
            }
        }, 
        {
            activity: "Put x more in your cart",
            description: "Add items to your cart to exceed $200 on this purchase and earn 5% equity",
            points: {
                type: SeedDataOperatorType.VARIABLE,
                value: 0.05,
                operator: Operator.MULTIPLY
            }
        }, 
        {
            activity: "Buy x per month",
            description: "Buy more than $250 in products in a single month and earn 10% of your purchase in equity",
            points: {
                type: SeedDataOperatorType.VARIABLE,
                value: 0.10,
                operator: Operator.MULTIPLY
            }
        }, 
        {
            activity: "Manual Award",
            description: "Just for being an amazing customer! Here's some equity to say Thank You.",
            points: {
                type: SeedDataOperatorType.FIXED,
                value: 50
            }
        }
    ],
    RESTAURANT: [
        {
            activity: "Gift Card",
            description: "Buy a $200 gift card and earn $40 in equity",
            points: {
                type: SeedDataOperatorType.FIXED,
                value: 40
            }
        }, 
        {
            activity: "Owners Club",
            description: "Join the Owners Club and earn $100 of equity",
            points: {
                type: SeedDataOperatorType.FIXED,
                value: 100
            }
        }, 
        {
            activity: "Use our delivered",
            description: "Use our delivery option instead of an alternative and earn 10% of your purchase as equity",
            points: {
                type: SeedDataOperatorType.VARIABLE,
                value: 0.10,
                operator: Operator.MULTIPLY
            }
        }, 
        {
            activity: "Order via app",
            description: "Order via our mobile app and earn 5% of your purchase in equity",
            points: {
                type: SeedDataOperatorType.VARIABLE,
                value: 0.05,
                operator: Operator.MULTIPLY
            }
        }, 
        {
            activity: "Manual Award",
            description: "Just for being an amazing customer! Here’s some equity to say Thank You.",
            points: {
                type: SeedDataOperatorType.FIXED,
                value: 50
            }
        }, 
        {
            activity: "Spend x per month",
            description: "Spend more than $250 with us in a single month and earn 10% of your purchase in equity",
            points: {
                type: SeedDataOperatorType.VARIABLE,
                value: 0.10,
                operator: Operator.MULTIPLY
            }
        }
    ],
    SPORTS: [
        {
            activity: "Buy Season Tix early",
            description: "Buy your season tickets before May 1 and unlock 10% of your purchase in equity",
            points: {
                type: SeedDataOperatorType.VARIABLE,
                value: 0.10,
                operator: Operator.MULTIPLY
            }
        }, 
        {
            activity: "Specific game package",
            description: "Buy one of our promo game packages and unlock 10% of your purchase in equity",
            points: {
                type: SeedDataOperatorType.VARIABLE,
                value: 0.10,
                operator: Operator.MULTIPLY
            }
        }, 
        {
            activity: "Buy x amount of merch",
            description: "Buy more than $250 in team merchandise and earn 15% of your purchase as equity",
            points: {
                type: SeedDataOperatorType.VARIABLE,
                value: 0.15,
                operator: Operator.MULTIPLY
            }
        }, 
        {
            activity: "Post on social media",
            description: "Re-post one of our posts on social media with the #takenote hashtag and earn $5 in equity",
            points: {
                type: SeedDataOperatorType.FIXED,
                value: 5
            }
        }, 
        {
            activity: "Subscribe to streaming",
            description: "Subscribe to the Streaming+ team feed and earn 10% of your monthly fee in equity",
            points: {
                type: SeedDataOperatorType.VARIABLE,
                value: 0.10,
                operator: Operator.MULTIPLY
            }
        }
    ],
    EMPLOYEES: [
        {
            activity: "Great Job!",
            description: "Do something exceptional and get an equity award!",
            points: {
                type: SeedDataOperatorType.FIXED,
                value: 25
            }
        },        
        {
            activity: "On Time",
            description: "Be on time for every shift for a month and earn $200 in equity",
            points: {
                type: SeedDataOperatorType.FIXED,
                value: 200
            }
        },        
        {
            activity: "Work the Tough Shift",
            description: "Offer to take one of the less desirable / holiday / weekend shifts and earn $50 equity",
            points: {
                type: SeedDataOperatorType.FIXED,
                value: 50
            }
        },        
        {
            activity: "Customer Satisfaction Award",
            description: "Get a positive comment / feedback / review from a customer and earn $25 in equity",
            points: {
                type: SeedDataOperatorType.FIXED,
                value: 25
            }
        },        
        {
            activity: "Personal Goal Achieved",
            description: "Achieve one of your approved personal work goals and earn $100 equity",
            points: {
                type: SeedDataOperatorType.FIXED,
                value: 100
            }
        },        
        {
            activity: "Team Goal Achieved",
            description: "Achieve an approved team goal and all members of the team earn $100 equity",
            points: {
                type: SeedDataOperatorType.FIXED,
                value: 100
            }
        },        
        {
            activity: "Company Revenue Goal",
            description: "If the company hits our quarterly revenue goal, all team members get $250 equity",
            points: {
                type: SeedDataOperatorType.FIXED,
                value: 250
            }
        },        
        {
            activity: "Employee Referral",
            description: "Refer an employee that is hired and completes their 90 days, earn $500 equity",
            points: {
                type: SeedDataOperatorType.FIXED,
                value: 500
            }
        },        
        {
            activity: "Cusomer Referral",
            description: "Refer a customer to the company and earn 5% of their contract, as they pay us, in equity",
            points: {
                type: SeedDataOperatorType.VARIABLE,
                value: 0.05,
                operator: Operator.MULTIPLY
            }
        },        
        {
            activity: "Referral from Advisor",
            description: "Refer a customer to the company and earn 5% of their contract, as they pay us, in equity",
            points: {
                type: SeedDataOperatorType.VARIABLE,
                value: 0.05,
                operator: Operator.MULTIPLY
            }
        },        
        {
            activity: "Advisor Meeting",
            description: "Attend and participate in an advisor meeting / brainstorm session and earn $250 equity",
            points: {
                type: SeedDataOperatorType.FIXED,
                value: 250
            }
        },        
    ]
};

type CategoryLabelsType = { [key in SeedDataCategory]: string };

const CategoryLabels: CategoryLabelsType = {
    ECOMMERCE: "Ecommerce",
    RESTAURANT: "Restaurant",
    SPORTS: "Sports",
    EMPLOYEES: "Employees",
};

const DataOptionsLabels = {
    ALL: "Choose All",
    PROPOSALS: "Proposals",
    MEMBERS: "Members",
    POINT_AWARD_EVENTS: "Point Award Events",
    ACTIVITIES: "Activities",
    POSTS: "Posts",
}

const GeneralLabels = {
    CATEGORY: "Category",
    GENERATE: "Generate Seed Data",
    REMOVE: "Remove Seed Data",
    DATA_GENERATED: "Seed Data Generated",
    DATA_CONFIRMATION: "Seeded Data",
    CHOOSE_CATEGORY: "Seed Data Category",
    REMOVE_SUBTITLE: "Are you sure you want to remove the seed data? This will remove all seed data for the chosen collective.",
    GENERATE_SUBTITLE: "Are you sure you want to generate seed data?"
}

const ButtonLabels = {
    GENERATE: "Generate",
    REMOVE: "Remove",
    CLOSE: "Close",
}


export {ButtonLabels, DataOptionsLabels, DefaultActivityInputs, CategoryLabels, GeneralLabels};