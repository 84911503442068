import { Button, CardContent, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import SeasonDTO from "@piefi-platform/types-lib/dtos/season.dto";
import { Card, DateFormatter, SubContent } from "components";
import { SEASONS_VIEW_LABELS } from "constants/seasons-view.labels";
import { useNotification } from "hooks";
import useSeasonService from "hooks/services/seasons-service/use-season-service";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";

const Seasons = () => {
  const [currentSeason, setCurrentSeason] = useState<SeasonDTO>(
    {} as SeasonDTO
  );
  const [loading, setLoading] = useState(true);
  let { daoId } = useParams();
  const { getCurrentSeason, endCurrentSeason } = useSeasonService();
  const { notify } = useNotification();
  const { NOTIFICATIONS, BUTTONS, LABELS } = SEASONS_VIEW_LABELS;

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      if (!daoId) {
        notify(NOTIFICATIONS.DAO_ID_MISSING);
        return;
      }
      const results = await getCurrentSeason(daoId);
      setCurrentSeason(results.data[0]);
    } catch (error) {
      return;
    } finally {
      setLoading(false);
    }
  }, [NOTIFICATIONS.DAO_ID_MISSING, daoId, getCurrentSeason, notify]);

  const endSeason = useCallback(async () => {
    setLoading(true);
    if (!daoId) return;
    try {
      await endCurrentSeason(daoId);
      await fetchData();
    } catch (error: any) {
      notify(error.toString);
    } finally {
      setLoading(false);
    }
  }, [daoId, endCurrentSeason, fetchData, notify]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {!loading && (
        <Stack spacing={2}>
          <Card>
            <CardContent>
              <Typography variant={"h4"} marginBottom={"1rem"}>
                {LABELS.CURRENT_SEASON}: {currentSeason.seasonNumber}
              </Typography>
              <SubContent>
                {LABELS.ID}: {currentSeason.id}
              </SubContent>
              <SubContent>
                {LABELS.SEASON_NUMBER}: {currentSeason.seasonNumber}
              </SubContent>
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={2}
                marginTop={"1rem"}
              >
                <Typography variant={"body1"}>{LABELS.START_DATE}:</Typography>
                <Typography variant={"h6"}>
                  {<DateFormatter date={currentSeason.startDate} />}
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Typography variant={"body1"}>{LABELS.END_DATE}:</Typography>
                <Typography variant={"h6"}>
                  {currentSeason.endDate ? (
                    <Stack display="row">
                      <DateFormatter
                        date={currentSeason.endDate || ""}
                      ></DateFormatter>
                    </Stack>
                  ) : (
                    "-"
                  )}
                </Typography>
              </Stack>
            </CardContent>
          </Card>
          {currentSeason.seasonNumber === 0 && (
            <Button
              variant={"contained"}
              sx={{ margin: "1rem auto 0 0 !important" }}
              onClick={endSeason}
            >
              {BUTTONS.END_SEASON}
            </Button>
          )}
        </Stack>
      )}
    </>
  );
};

export default Seasons;
