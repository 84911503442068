import { Button, Stack, Typography } from "@mui/material";
import DAO_VIEW_LABELS from "constants/dao-view.labels";
import { ROUTES } from "constants/routes"; // Make sure ROUTES is imported or passed as props if needed
import useDao from "hooks/use-dao";
import { useNavigate } from "react-router";
import { DaoVisibilityState } from "types/enums"; // Adjust this import as well

const DaoGeneralInformation = () => {
  const { currentDao } = useDao();
  const navigate = useNavigate();
  const { METADATA_LABEL, BUTTON_LABEL } = DAO_VIEW_LABELS;

  return (
    <Stack>
      <Button
        variant={"contained"}
        onClick={() => navigate(ROUTES.DAOS_UPDATE(currentDao?.id))}
        sx={{ margin: "1rem auto 2rem 0" }}
      >
        {BUTTON_LABEL.UPDATE_DAO}
      </Button>
      <Stack direction={"row"} spacing={2}>
        <Typography variant={"h6"}>{METADATA_LABEL.STATE}</Typography>
        <Typography variant={'h6'}>{currentDao?.state}</Typography>
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <Typography variant={"h6"}>{METADATA_LABEL.MEMBERS}</Typography>
        <Typography variant={'h6'}>{currentDao?.memberCount}</Typography>
      </Stack>
      <Typography variant={'h6'}>
        {currentDao?.isPublic ? DaoVisibilityState.PUBLIC : DaoVisibilityState.PRIVATE}
      </Typography>
    </Stack>
  );
};

export default DaoGeneralInformation;
