
enum SeedDataCategory {
    ECOMMERCE = 'ECOMMERCE',
    RESTAURANT = 'RESTAURANT',
    SPORTS = 'SPORTS',
    EMPLOYEES = 'EMPLOYEES',
};

enum SeedDataOperatorType {
    FIXED = 'FIXED',
    VARIABLE = 'VARIABLE',
}

enum Operator {
    MULTIPLY = 'MULTIPLY',
    DIVIDE = 'DIVIDE',
    SUBTRACT = 'SUBTRACT',
    ADD = 'ADD'
};

export {SeedDataCategory, SeedDataOperatorType, Operator};