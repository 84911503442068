import { Autocomplete, TextField } from "@mui/material";
import { UserSimpleDTO } from "@piefi-platform/types-lib";
import { useUserService } from "hooks";
import React, { useEffect, useState } from "react";
import UserAutoCompleteProps from "./UserAutoComplete.props";

const UserAutoComplete = ({userSelected, placeholder = 'Username'}: UserAutoCompleteProps): React.ReactElement => {
  const [userOptions, setUserOptions] = useState<UserSimpleDTO[]>([]);
  const [inputValue, setInputValue] = useState('');
  const { getUsers } = useUserService();

  useEffect(() => {
    if(inputValue) {
        const fetchList = async () => {
          const {data} = await getUsers(inputValue, false, {page: 0, size: 25});
          setUserOptions([...data]);
        }
        fetchList()  
    }
  }, [getUsers, inputValue])

  return (
    <Autocomplete
      options={userOptions.map((item) => ({label: item.username, id: item.id}))}
      filterOptions={(x) => x}
      onInputChange={(e, val) => setInputValue(val)}
      sx={{width: 300}}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      inputValue={inputValue}
      onChange={(e,v) => userSelected(userOptions.find(i => i.id === v?.id))}
      renderInput={(params) => <TextField {...params} label={placeholder} />}
    />
  )
}

export default UserAutoComplete;