import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import BaseModal from '../base-modal/BaseModal';

interface BaseModalProps {
  cancelText?: string;
  confirmText?: string;
  children?: React.ReactNode;
  textContent?: string;
  confirmDisabled?: boolean;
  open: boolean;
  subtitle?: string;
  title?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationModal = ({
  onCancel,
  cancelText = 'Cancel',
  onConfirm,
  confirmText = 'Yes',
  confirmDisabled,
  children,
  open,
  subtitle,
  title = 'Are you sure?',
  textContent
}: BaseModalProps): React.ReactElement => {
  
  const handleKeyPress = (key: string): void => {
    // https://www.w3.org/TR/uievents-key/#keys-modifier
    if (key === 'Enter') onConfirm();

    if (key === 'Escape') onCancel();
  };

  return (
    <BaseModal title={title} isOpen={open} close={onCancel} width={450}>
      <Stack onKeyDown={({ key }) => handleKeyPress(key)}>
        {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
        {textContent && <Typography variant="body1">{textContent}</Typography>}

        <Stack direction="row" spacing={2} marginTop="2.5rem">
          <Button
            data-testid="modal-cancel-button"
            variant="outlined"
            color="primary"
            size="large"
            onClick={onCancel}
          >
            {cancelText}
          </Button>
          <Button
            data-testid="modal-confirm-button"
            variant="contained"
            color="primary"
            size="large"
            disabled={confirmDisabled}
            onClick={onConfirm}
          >
            {confirmText}
          </Button>
        </Stack>
      </Stack>
    </BaseModal>
  );
};

export default ConfirmationModal;
