import React, { createContext, ReactNode, useCallback, useRef, useState } from 'react';
import {
  Notification,
  NotificationOptions,
  NotificationContextType,
  initNotificationContextType,
  initNotificationOptions
} from '../types/models';

const defaultNotice: NotificationOptions = initNotificationOptions();
const initialState: NotificationContextType = initNotificationContextType();

const NotificationContext = createContext(initialState);

const NotificationProvider = ({ children }: { children: ReactNode }): React.ReactElement => {
  const [notifying, setNotifying] = useState(false);
  const notification = useRef<Notification>(initialState.notification);

  const notify = useCallback((message: string, options?: NotificationOptions) => {
    const {
      position = defaultNotice.position,
      duration = defaultNotice.duration,
      severity = defaultNotice.severity
    } = options ?? {};

    notification.current = {
      message,
      position,
      duration,
      severity
    };
    setNotifying(true);
  }, []);

  const resetNotifier = useCallback(() => {
    setNotifying(false);
    notification.current.message = '';
  }, []);

  return (
    <NotificationContext.Provider
      value={{ notifying, notification: notification.current, notify, resetNotifier }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
export { NotificationContext };
