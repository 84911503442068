import { Box, IconButton, styled } from '@mui/material';
import { SxProps, Theme } from '@mui/system';

const modalStyled = (width: number): SxProps<Theme> => {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width > -1 ? width : '100%',
    bgcolor: 'background.paper',
    pt: 3,
    px: 3,
    pb: 3
  };
};

const BoxStyled = styled(Box)({
  marginTop: '1rem !important'
});

const IconButtonStyled = styled(IconButton)({
  position: 'absolute',
  right: 0,
  top: 0
});

export default modalStyled;
export { BoxStyled, IconButtonStyled };
