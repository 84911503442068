import {
  CompanyAllocationList,
  DaoDocumentManager,
  DaoGeneralInformation,
  EquityAllocationList,
  GenerateSeedData,
  LoadingScreen,
  Seasons,
  TemporaryInvitationManager,
  ValuationListFilter,
} from "components";
import { lazy, Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";
// import useAuth from '../hooks/use-auth';
import { ROUTES } from "constants/routes";
import { useAuth } from "hooks";
import { LogoOnlyLayout } from "layouts";
import Layout from "components/headers/Header";
import DocumentCreate from "../components/documents/DocumentCreate";
import { DaoProvider } from "../contexts";
import CompanyAllocationCreate from "../pages/company-equity-allocation/CompanyAllocationCreate";
import CompanyAllocationUpdate from "../pages/company-equity-allocation/CompanyAllocationUpdate";

const Loadable = (Component: any) => (props: any) => {
  const isFixed = true;

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(isFixed && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
          speed={2.25}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

const PrivateRoute = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const { token } = useAuth();
  if (!token) {
    return <Navigate to={ROUTES.AUTH} replace />;
  }

  // auth.currentUser?.email?.includes("piefi")

  return <>{children}</>;
};

const DaoPrivateWrapper = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  return (
    <PrivateRoute>
      <DaoProvider>{children}</DaoProvider>
    </PrivateRoute>
  );
};
export default function Router(): React.ReactElement | null {
  return useRoutes([
    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: ROUTES.NOT_FOUND, element: <p>Not Found</p> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: ROUTES.AUTH,
      element: <LogoOnlyLayout />,
      children: [
        {
          path: "",
          element: <Authentication />,
        },
      ],
    },
    {
      path: ROUTES.DASHBOARD,
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          ),
        },
      ],
    },
    {
      path: ROUTES.COMPANIES_ROOT,
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <PrivateRoute>
              <CompanyCreate />
            </PrivateRoute>
          ),
        },
        {
          path: ROUTES.COMPANY_ALLOCATION_CREATE(),
          element: (
            <PrivateRoute>
              <CompanyAllocationCreate />
            </PrivateRoute>
          ),
        },
        {
          path: ROUTES.COMPANY_ALLOCATION_UPDATE(),
          element: (
            <PrivateRoute>
              <CompanyAllocationUpdate />
            </PrivateRoute>
          ),
        },
        {
          path: ROUTES.VALUATION_CREATE(),
          element: (
            <PrivateRoute>
              <ValuationCreate />
            </PrivateRoute>
          ),
        },
        {
          path: ROUTES.COMPANIES_VIEW(),
          element: (
            <PrivateRoute>
              <CompanyView />
            </PrivateRoute>
          ),
          children: [
            {
              path: ROUTES.COMPANY_ALLOCATION_LIST(),
              element: (
                <PrivateRoute>
                  <CompanyAllocationList />
                </PrivateRoute>
              ),
            },
            {
              path: ROUTES.VALUATION_LIST(),
              element: (
                <PrivateRoute>
                  <ValuationListFilter />
                </PrivateRoute>
              ),
            },
          ],
        },
        {
          path: ROUTES.DAOS_CREATE(),
          element: (
            <PrivateRoute>
              <DaoCreate />
            </PrivateRoute>
          ),
        },
      ],
    },
    {
      path: ROUTES.DAOS_ROOT(),
      element: (
        <DaoPrivateWrapper>
          <Layout />
        </DaoPrivateWrapper>
      ),
      children: [
        {
          path: "",
          element: (
            <DaoPrivateWrapper>
              <DaoView />
            </DaoPrivateWrapper>
          ),
        },
        {
          path: ROUTES.DAOS_UPDATE(),
          element: (
            <DaoPrivateWrapper>
              <DaoUpdate />
            </DaoPrivateWrapper>
          ),
        },
        {
          path: ROUTES.DAOS_DOCUMENTS_CREATE(),
          element: (
            <DaoPrivateWrapper>
              <DocumentCreate />
            </DaoPrivateWrapper>
          ),
        },
        {
          path: ROUTES.DAOS_DOCUMENT_VIEW(),
          element: (
            <DaoPrivateWrapper>
              <DaoDocumentView />
            </DaoPrivateWrapper>
          ),
        },
        {
          path: ROUTES.DAOS_VIEW(),
          element: (
            <DaoPrivateWrapper>
              <DaoView />
            </DaoPrivateWrapper>
          ),
          children: [
            {
              path: ROUTES.DAOS_GENERAL_INFO(),
              element: (
                <DaoPrivateWrapper>
                  <DaoGeneralInformation />
                </DaoPrivateWrapper>
              ),
            },
            {
              path: ROUTES.DAOS_EQUITY_ALLOCATION(),
              element: (
                <DaoPrivateWrapper>
                  <EquityAllocationList />
                </DaoPrivateWrapper>
              ),
            },
            {
              path: ROUTES.DAOS_SEASONS(),
              element: (
                <DaoPrivateWrapper>
                  <Seasons />
                </DaoPrivateWrapper>
              ),
            },
            {
              path: ROUTES.DAOS_TEMP_INVITATIONS(),
              element: (
                <DaoPrivateWrapper>
                  <TemporaryInvitationManager />
                </DaoPrivateWrapper>
              ),
            },
            {
              path: ROUTES.DAOS_DOCUMENTS(),
              element: (
                <DaoPrivateWrapper>
                  <DaoDocumentManager />
                </DaoPrivateWrapper>
              ),
            },
            {
              path: ROUTES.DAOS_SEED_DATA(),
              element: (
                <DaoPrivateWrapper>
                  <GenerateSeedData />
                </DaoPrivateWrapper>
              ),
            },
          ],
        },
      ],
    },
  ]);
}

// const NotFound = Loadable(lazy(() => import('pages/Page404')));
const Authentication = Loadable(
  lazy(() => import("pages/auth/Authentication"))
);
const Dashboard = Loadable(lazy(() => import("pages/dashboard/Dashboard")));
const CompanyCreate = Loadable(
  lazy(() => import("pages/company-create/CompanyCreate"))
);
const CompanyView = Loadable(
  lazy(() => import("pages/company-view/CompanyView"))
);
const DaoView = Loadable(lazy(() => import("pages/dao-view/DaoView")));
const DaoCreate = Loadable(lazy(() => import("pages/dao-create/DaoCreate")));
const DaoDocumentView = Loadable(
  lazy(() => import("pages/dao-document/DaoDocumentView"))
);
const DaoUpdate = Loadable(lazy(() => import("pages/dao-update/DaoUpdate")));
const ValuationCreate = Loadable(
  lazy(() => import("pages/valuation-create/ValuationCreate"))
);
