import { useCallback, useEffect, useRef } from 'react';

export default function useLocalStorageRef<ValueType>(
  key: string,
  defaultValue?: ValueType
): [any, (newValue: ValueType) => void] {
  const localStorageValue = localStorage.getItem(key);
  const value = useRef(localStorageValue === null ? defaultValue : JSON.parse(localStorageValue));

  useEffect(() => {
    const listener = (e: StorageEvent) => {
      if (e.storageArea === localStorage && e.key === key) {
        value.current = e.newValue ? JSON.parse(e.newValue) : e.newValue;
      }
    };
    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, [key, defaultValue]);

  const setValueInLocalStorage = useCallback(
    (newValue: ValueType) => {
      const result = typeof newValue === 'function' ? newValue(value.current) : newValue;
      value.current = result;
      localStorage.setItem(key, JSON.stringify(result));
    },
    [key]
  );

  return [value.current, setValueInLocalStorage];
}
