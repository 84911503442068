import { Button, CardContent, Stack, Typography } from "@mui/material";
import {
  CompanyAllocation,
  ValuationEventDTO,
} from "@piefi-platform/types-lib";
import { Card, DateFormatter, SubContent } from "components";
import { ROUTES } from "constants/routes";
import VALUATION_VIEW_LABELS from "constants/valuation-view-labels";
import {
  useCompanyAllocationService,
  useNotification,
  useValuationService,
} from "hooks";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

const ValuationListFilter = () => {
  const [companyAllocations, setCompanyAllocations] = useState<
    CompanyAllocation[]
  >([]);
  const [valuationEvents, setValuationEvents] = useState<ValuationEventDTO[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  let { companyId } = useParams();
  const { getValuations } = useValuationService();
  const { getPageOfCompanyAllocations } = useCompanyAllocationService();
  const { notify } = useNotification();
  const { NOTIFICATIONS, BUTTONS, LABELS } = VALUATION_VIEW_LABELS;
  const navigate = useNavigate();

  const fetchAllocationData = useCallback(async () => {
    setLoading(true);
    try {
      if (!companyId) {
        notify(NOTIFICATIONS.DAO_ID_MISSING);
        return;
      }
      const results = await getPageOfCompanyAllocations(companyId, {
        page: 0,
        size: 100,
      });
      setCompanyAllocations(results.data);
    } catch (error) {
      return;
    }
  }, [
    NOTIFICATIONS.DAO_ID_MISSING,
    companyId,
    getPageOfCompanyAllocations,
    notify,
  ]);

  const fetchValuationData = useCallback(async () => {
    setLoading(true);
    try {
      if (!companyId) {
        notify(NOTIFICATIONS.DAO_ID_MISSING);
        return;
      }
      const results = await getValuations(companyId, 15, 0);
      setValuationEvents(results.data);
    } catch (error) {
      return;
    } finally {
      setLoading(false);
    }
  }, [NOTIFICATIONS.DAO_ID_MISSING, companyId, getValuations, notify]);

  useEffect(() => {
    fetchAllocationData();
    fetchValuationData();
  }, [fetchAllocationData, fetchValuationData]);

  return (
    <>
      {!loading && (
        <Stack>
          <Typography variant={"h6"}>
            {LABELS.VALUATION_COUNT}: {valuationEvents.length}
          </Typography>
          <Button
            variant={"contained"}
            disabled={
              !companyAllocations.length || companyAllocations.length === 0
            }
            onClick={() => navigate(ROUTES.VALUATION_CREATE(companyId))}
            sx={{ margin: "1rem auto 0 0" }}
          >
            {BUTTONS.CREATE_VALUATION}
          </Button>
          {(!companyAllocations.length || companyAllocations.length === 0) && (
            <Typography variant={"caption"} color={"red"} marginTop={"0.25rem"}>
              {BUTTONS.CREATE_ALLOCATION}
            </Typography>
          )}
          <Stack spacing={2} marginTop={"2rem"}>
            {valuationEvents.map((event) => (
              <Card key={event.id}>
                <CardContent>
                  <DateFormatter date={event.createdAt} />
                  <SubContent>
                    {LABELS.TOTAL_VALUE}: {(event as any).sharePrice}
                  </SubContent>
                  <SubContent>
                    {LABELS.BASE_VALUATION}: {event.isBaseValuation.toString()}
                  </SubContent>
                </CardContent>
              </Card>
            ))}
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default ValuationListFilter;
