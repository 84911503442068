import { SvgIcon, SvgIconProps } from '@mui/material';

export default function FailIcon(props: SvgIconProps): React.ReactElement {
  const width = props.width ? `${props.width}px` : '16px';
  const height = props.height ? `${props.height}px` : '16px';

  return (
    <SvgIcon style={{ width, height }} viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8107 0.936286C10.6784 0.742291 10.4582 0.626917 10.2234 0.628588C9.9886 0.63026 9.77005 0.748759 9.64055 0.944619C9.14286 1.6973 7.95436 2.27538 6.34517 2.05905C4.95272 1.87186 3.92856 2.17524 3.13488 2.67835C2.53765 3.05694 2.06928 3.56094 1.72657 3.92973L1.72653 3.92977C1.64667 4.01571 1.57363 4.09431 1.50737 4.16226C1.29615 4.36799 1.23526 4.68673 1.35714 4.9563C1.37411 4.99398 1.39456 5.03047 1.41848 5.06527L8.08003 15.0576C8.29593 15.3815 8.7335 15.469 9.05736 15.2531C9.38121 15.0372 9.46873 14.5996 9.25282 14.2757L6.87553 10.7098C7.01916 10.4101 7.15825 10.1419 7.43356 9.89572C7.75832 9.60532 8.34802 9.29735 9.5427 9.29735C10.7595 9.29735 11.7239 9.21272 12.5348 8.90613C13.3784 8.58719 13.9936 8.05335 14.5653 7.28484C14.7447 7.04373 14.7514 6.71542 14.5821 6.46715L10.8107 0.936286ZM6.00261 9.40041C6.13489 9.2132 6.29612 9.02196 6.494 8.84502C7.13568 8.27122 8.08884 7.88783 9.5427 7.88783C10.7259 7.88783 11.4758 7.79962 12.0363 7.58769C12.4417 7.43441 12.7796 7.20398 13.1215 6.82706L10.1491 2.46802C9.17824 3.28761 7.7279 3.66714 6.15738 3.45601C5.09136 3.3127 4.40124 3.54447 3.88953 3.86884C3.49353 4.11987 3.20147 4.41952 2.89791 4.74336L6.00261 9.40041Z"
        fill={props.fill || '#8A8A8A'}
      />
    </SvgIcon>
  );
}
