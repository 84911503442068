import { BaseQueryParams, EquityAllocation } from "@piefi-platform/types-lib";
import { AxiosResponse } from "axios";
import { useCallback } from "react";
import EquityAllocationDTO from "types/models/equity-allocation.model";
import useHttp from "../../use-http";
import { daoUrl } from "../dao-service/use-dao-service.url";
import EquityAllocationService from "./use-equity-allocation-service.model";

const useEquityAllocationService = (): EquityAllocationService => {
  const { get, post } = useHttp();

  const createEquityAllocation = useCallback(
    (
      daoId: string,
      equityAllocation: EquityAllocation
    ): Promise<AxiosResponse<{}>> => {
      return post<{}>(
        `${daoUrl}/${daoId}/equity-allocations`,
        equityAllocation
      );
    },
    [post]
  );

  const getPageOfEquityAllocations = useCallback(
    (
      daoId: string,
      { page, size }: BaseQueryParams
    ): Promise<AxiosResponse<EquityAllocationDTO[]>> => {
      return get<EquityAllocationDTO[]>(
        `${daoUrl}/${daoId}/equity-allocations?size=${size}&page=${page}`
      );
    },
    [get]
  );

  return {
    createEquityAllocation,
    getPageOfEquityAllocations,
  };
};

export default useEquityAllocationService;
