import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { useCallback, useState } from "react";
import { TEMP_INVITATIONS_LABELS } from "../../constants/temp-invitations.labels";
import { useDaoService, useNotification } from "../../hooks";
import useDao from "../../hooks/use-dao";
import { Severity } from "../../types/enums";
import BaseModal from "../modals";

interface UploadItem {
  id: string;
  index: number;
  conflicts: string;
  email: string;
  code?: string;
}

const TemporaryInvitationManager = () => {
  const { currentDao } = useDao();

  const [file, setFile] = useState<File | undefined>();
  const [array, setArray] = useState<UploadItem[]>([]);
  const [conflicts, setConflicts] = useState<string[]>([]);
  const [conflictModalOpen, setConflictModalOpen] = useState<boolean>(false);
  const { createTemporaryInvites } = useDaoService();
  const [loading, setLoading] = useState<boolean>(false);
  const notify = useNotification();
  const {ACTIONS, ERROR, SUCCESS, INFO} = TEMP_INVITATIONS_LABELS;
  const {CONFLICT, PARSE, SAVE} = ACTIONS;
  const fileReader = new FileReader();

  const handleOnChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const csvFileToArray = (str: string) => {
    const baseColumns = ["index", "conflicts"];
    const csvHeader = str
      .slice(0, str.indexOf("\n"))
      .split(",")
      .concat(baseColumns)
      .map((i) => i.trim().toLowerCase());

    const csvRows = str.slice(str.indexOf("\n") + 1).split("\n");

    const array: UploadItem[] = csvRows.map((i, rowIndex) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object: any, header: any, index: any) => {
        console.log(object, header, index);
        object[header] = values[index]?.trim();
        return object;
      }, {} as UploadItem);

      obj["index"] = rowIndex;
      obj["conflicts"] = false;
      return obj;
    });

    // iterate over the array and check if there are duplicated emails or duplicated codes, if so set conflicts to which index they are duplicated
    const emailMap = new Map<string, number>();
    const codeMap = new Map<string, number>();
    const tempConflicts: string[] = [];
    array.forEach((item) => {
      if (emailMap.has(item.email)) {
        const conflictMessage = `${
          item.conflicts || ""
        } This email conflicts with the item found on row: ${emailMap.get(
          item.email
        )}`;
        tempConflicts.push(`${item.index}: ${conflictMessage}`);
        item.conflicts = conflictMessage;
      }

      if (codeMap.has(item.code as any)) {
        const conflictMessage = `${
          item.conflicts || ""
        } This code conflicts with the item found on row:  ${codeMap.get(
          item.code as any
        )}`;
        tempConflicts.push(`${item.index}: ${conflictMessage}`);
        item.conflicts = conflictMessage;
      }

      emailMap.set(item.email, item.index);
      codeMap.set(item.code as any, item.index);
    });

    setConflicts(tempConflicts);
    console.log(array);
    setArray(array);
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = function (event) {
        const text = event.target?.result;
        csvFileToArray(text as string);
      };

      fileReader.readAsText(file);
    }
  };

  const submit = useCallback(async () => {
    try {
      if (!currentDao) return;
      setLoading(true);

      const invites = array.map((item) => ({
        email: item.email,
        externalIdOnCreate: item.code,
      }));

      const { data } = await createTemporaryInvites(currentDao?.id, invites);
      
      setArray((_) => []);
      setConflicts((_) => []);
      setFile(undefined);

      notify.notify(
        `${SUCCESS(data.duplicateItems.length)}`,
        {
          severity: data.duplicateItems.length
            ? Severity.Warning
            : Severity.Success,
        }
      );
    } catch (error) {
      notify.notify(`${ERROR}`, {
        severity: Severity.Error,
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [currentDao, array, createTemporaryInvites, notify, SUCCESS, ERROR]);

  const headerKeys = Object.keys(Object.assign({}, ...array));

  return (
    <Stack>
      <BaseModal
        isOpen={conflictModalOpen}
        close={() => setConflictModalOpen(false)}
      >
        <Stack>
          {conflicts.map((conflict) => (
            <Typography key={conflict}>{conflict}</Typography>
          ))}
        </Stack>
      </BaseModal>
      <Stack spacing={1}>
        <Typography variant="body2">{INFO}</Typography>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack direction={"row"} marginTop={'1rem'}>
            <Button variant="contained" component="label">
              Upload File
              <input
                type="file"
                hidden
                accept=".csv"
                onChange={handleOnChange}
              />
            </Button>
            {file && <Button onClick={handleOnSubmit}>{PARSE}</Button>}
          </Stack>

          {array.length > 0 && (
            <Button variant="outlined" onClick={submit} disabled={loading}>
              {SAVE}
            </Button>
          )}
        </Stack>
        {conflicts.length > 0 && (
          <Button
          variant="outlined"
          color="warning"
            onClick={() => setConflictModalOpen(true)}
          >{`${CONFLICT(conflicts.length)}`}</Button>
        )}
      </Stack>
      <Stack></Stack>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headerKeys.map((key) => (
                <TableCell key={key}>{key}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {array.map((item) => (
              <TableRow
                style={{ backgroundColor: item.conflicts ? "yellow" : "white" }}
                key={item.index}
              >
                {Object.values(item).map((val: any, index) => (
                  <TableCell key={index}>{val}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
export default TemporaryInvitationManager;
