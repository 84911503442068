import { Button, CardContent, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Card, DateFormatter, SubContent } from "components";
import EQUITY_ALLOCATION from "constants/company-allocation.labels";
import { ROUTES } from "constants/routes";
import { useNotification } from "hooks";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import useCompanyAllocationService from "../../hooks/services/company-allocation-service/company-allocation.service";
import CompanyAllocation from "../../types/models/company-allocation.model";
import { numberFormatter } from "utils";

const CompanyAllocationList = () => {
  const [companyAllocations, setCompanyAllocations] = useState<
    CompanyAllocation[]
  >([]);
  const [loading, setLoading] = useState(true);
  let { companyId } = useParams();
  const { getPageOfCompanyAllocations } = useCompanyAllocationService();
  const { notify } = useNotification();
  const { NOTIFICATIONS, BUTTONS, LABELS } = EQUITY_ALLOCATION;
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      if (!companyId) {
        notify(NOTIFICATIONS.DAO_ID_MISSING);
        return;
      }
      const results = await getPageOfCompanyAllocations(companyId, {
        page: 0,
        size: 100,
      });
      setCompanyAllocations(results.data);
    } catch (error) {
      console.error(error);
      notify(NOTIFICATIONS.ERROR);
      return;
    } finally {
      setLoading(false);
    }
  }, [
    NOTIFICATIONS.DAO_ID_MISSING,
    NOTIFICATIONS.ERROR,
    companyId,
    getPageOfCompanyAllocations,
    notify,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {!loading && (
        <Stack>
          <Typography variant={"h6"}>
            {LABELS.EQUITY_ALLOCATION_COUNT} {companyAllocations.length}
          </Typography>
          <Button
            variant={"contained"}
            onClick={() =>
              navigate(ROUTES.COMPANY_ALLOCATION_CREATE(companyId))
            }
            sx={{ margin: "1rem auto 0 0" }}
          >
            {BUTTONS.CREATE}
          </Button>
          <Stack spacing={2} marginTop={"2rem"}>
            {companyAllocations.map((equityAllocation) => (
              <Card
                key={equityAllocation.id}
                onClick={() =>
                  navigate(
                    ROUTES.COMPANY_ALLOCATION_UPDATE(
                      companyId,
                      equityAllocation.id
                    )
                  )
                }
              >
                <CardContent>
                  <DateFormatter date={equityAllocation.createdAt} />
                  <SubContent>
                    {LABELS.VALUE_ALLOCATED}{" "}
                    {numberFormatter.formatterWithZeroDecimals(
                      equityAllocation.shareCount || 0
                    )}
                  </SubContent>
                </CardContent>
              </Card>
            ))}
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default CompanyAllocationList;
