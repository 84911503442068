import { DaoDTO, DaoUpdatePayload } from '@piefi-platform/types-lib';

export default interface DaoContextType {
  currentDao?: DaoDTO;
  updateCurrentDao: (dao: DaoDTO) => void;
  update: (daoId: string, payload: DaoUpdatePayload) => Promise<DaoDTO>;
}

export const initDaoContextType = (): DaoContextType => ({
  currentDao: undefined,
  updateCurrentDao: (dao: DaoDTO): void => {},
  update: (daoId: string, payload: DaoUpdatePayload): Promise<DaoDTO> => Promise.resolve({} as DaoDTO),
});
