import {
  BaseQueryParams,
  DaoCreatePayload,
  DaoDTO,
  DaoPublicDTO,
  DaoUpdatePayload,
  TemporaryInvitation,
} from "@piefi-platform/types-lib";
import { AxiosResponse } from "axios";
import { useCallback } from "react";
import useHttp from "../../use-http";
import DaoService from "./use-dao-service.model";
import { daoUrl } from "./use-dao-service.url";

const useDaoService = (): DaoService => {
  const { get, put, post } = useHttp();

  const createDao = useCallback(
    (company: DaoCreatePayload): Promise<AxiosResponse<DaoDTO>> => {
      return post<DaoDTO>(`${daoUrl}`, company as any);
    },
    [post]
  );

  const getPageOfDaos = useCallback(
    (params: BaseQueryParams): Promise<AxiosResponse<Array<DaoPublicDTO>>> => {
      return get<DaoPublicDTO[]>(
        `${daoUrl}?page=${params.page}&size=${params.size}${params.phrase ? `&phrase=${params.phrase}` : ""
        }`
      );
    },
    [get]
  );

  const updateDaoById = useCallback(
    (daoId: string, data: DaoUpdatePayload): Promise<AxiosResponse<DaoDTO>> => {
      return put<DaoUpdatePayload, AxiosResponse<DaoDTO>>(
        `${daoUrl}/${daoId}`,
        data
      );
    },
    [put]
  );

  const getDaoById = useCallback(
    (daoId: string): Promise<AxiosResponse<DaoDTO>> => {
      return get<DaoDTO>(`${daoUrl}/${daoId}`);
    },
    [get]
  );

  const activateDaoDomain = useCallback(
    (daoId: string): Promise<AxiosResponse<string>> => {
      return post(`${daoUrl}/${daoId}/subdomains`)
    },
    [post]
  );

  const getDaoDomainRecord = useCallback(
    (daoId: string): Promise<AxiosResponse<any>> => {
      return get(`${daoUrl}/${daoId}/subdomains`)
    },
    [get]
  );

  const createTemporaryInvites = useCallback(
    (daoId: string, payload: TemporaryInvitation[]): Promise<AxiosResponse<{duplicateItems: any[]}>> => {
    return post(`${daoUrl}/${daoId}/temporary-invitations`, {data: payload});
  }, [post])

  return {
    getPageOfDaos,
    updateDaoById,
    createDao,
    getDaoById,
    activateDaoDomain,
    getDaoDomainRecord,
    createTemporaryInvites
  };
};

export default useDaoService;
