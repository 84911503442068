import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function CopyIcon(props: SvgIconProps): React.ReactElement {
  const width = props.width ? `${props.width}px` : '16px';
  const height = props.height ? `${props.height}px` : '16px';

  return (
    <SvgIcon style={{ width, height }} viewBox="0 0 16 16" {...props}>
      <path
        id="Rectangle 718 (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99146 2.00004C4.99146 1.63185 5.28993 1.33337 5.65812 1.33337H12.6324C13.0006 1.33337 13.2991 1.63185 13.2991 2.00004V10.7179C13.2991 11.0861 13.0006 11.3846 12.6324 11.3846H5.65812C5.28993 11.3846 4.99146 11.0861 4.99146 10.7179V2.00004ZM6.32479 2.66671V10.0513H11.9658V2.66671H6.32479Z"
        fill={props.fill || '#BABABA'}
      />
      <path
        id="Vector 90 (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66675 13.3334V3.74365H4.00008V12.6667L10.8889 12.6665L10.8889 13.9999L3.33343 14.0001C3.15662 14.0001 2.98705 13.9298 2.86202 13.8048C2.73699 13.6798 2.66675 13.5102 2.66675 13.3334Z"
        fill={props.fill || '#BABABA'}
      />
    </SvgIcon>
  );
}
