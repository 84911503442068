import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { firebaseStorage } from "../config";

const uploadFile = async (storageLocation: string, file: File): Promise<string> => {
  const storageRef = ref(firebaseStorage, storageLocation);
  await uploadBytes(storageRef, file);
  const firebaseUrl = await getDownloadURL(storageRef);
  return firebaseUrl;
};

export default uploadFile;