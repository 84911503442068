export const AUTH = {
  WELCOME_TO_THE_UPSIDEDAO: 'Welcome to the UpsideDAO Admin'
};

export const AUTH_ERROR = {
  WRONG_PASSWORD: 'Wrong password',
  USER_NOT_FOUND: 'User not found',
  INVALID_EMAIL: 'Invalid email',
  ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL: 'The account already exists for that email',
  EMAIL_ALREADY_IN_USE: 'An account with this email address already exists',
  UNKNOWN_FIREBASE_ERROR: 'Unknown firebase error occurred'
};

export const LOGIN = {
  SIGN_IN_WITH_GOOGLE: 'Sign in with Google',
  SIGN_IN_WITH_GITHUB: 'Sign in with Github',
  SIGN_IN_WITH_EMAIL: 'Sign in with Email',
  SIGN_IN_WITH_EMAIL_TITLE: 'Sign in with email',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  FORGOT_MY_PASSWORD: 'I forgot my password',
  DONT_HAVE_AN_ACCOUNT: "Don't have an account",
  LOGOUT: 'Logout',
};

export const FORGOT_PASSWORD = {
  LEGEND: 'Forgot Password',
  SEND_EMAIL: 'Send Email',
  LEGEND_EMAIL_SENT: 'Email Sent',
  PLEASE_CHECK_YOUR_EMAIL:
    'Please check your email for instructions on how to reset your password. This could take up to a few minutes. Be patient.',
  BACK_TO_SIGN_IN: 'Back to Sign In'
};

export const CREATE_ACCOUNT = {
  LEGEND: 'Create Account',
  EMAIL_PLACEHOLDER: 'ex. johnny_pieface@gmail.com',
  MAKE_IT_GOOD: 'make it good',
  SIGN_UP_WITH_EMAIL: 'Sign up with email',
  ALREADY_HAVE_AN_ACCOUNT: 'I already have an account.',
  SIGN_IN_OVER_HERE: 'Sign in over here.',
  OR: 'or',
  USER_CREATED_SUCCESSFULLY: 'User created successfully!'
};

export const COMPLETE_PROFILE = {
  LEGEND: 'Complete Profile',
  SELECT_PROFILE_PICTURE: 'Select profile picture',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  PROFILE_BIO: 'Profile Bio',
  WHO_ART_THOU: 'Who art thou?',
  ENTER_THE_UPSIDEDAO: 'Enter the UpsideDAO',
  CHANGE_AVATAR: 'Change avatar',
  ERROR_USER_CREATION: 'Error creating user',
  BIO_IS_TOO_LARGE: 'The bio is too large',
  FILL_OUT_MISSING_INFO: 'Please fill out this missing information',
  SOCIAL_LINKS: 'Social Links',
  PLEASE_INCLUDE_ENTIRE_LINK: 'Please include the entire link',
  TWITTER: 'Twitter',
  LINKEDIN: 'LinkedIn',
  WEBSITE: 'Website',
  INVALID_IMAGE_ERROR: 'Please upload a valid image file (png, jpg or jpeg)',
  ADDRESS: 'Address',
  USERNAME: 'Username',
  USERNAME_UNAVAILABLE: 'username unavailable. try another.',
  USERNAME_AVAILABLE: 'username is available. cool.'
};
