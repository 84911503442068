import React, { useEffect, useState } from 'react';
import { Box, Modal, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import modalStyled, { BoxStyled, IconButtonStyled } from './BaseModal.style';
import IBaseModalProps from './BaseModal.props';
import CloseIcon from '../../../assets/icons/CloseIcon';

const BaseModal = ({
  children,
  title,
  isOpen,
  width = 400,
  close
}: IBaseModalProps): React.ReactElement => {
  const [open, setOpen] = useState(isOpen);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = (_: any, reason: string) => {
    reason !== 'backdropClick' && setOpen(false);
    close && close();
  };

  const closeModal = () => {
    setOpen(false);
    close && close();
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ ...modalStyled(!isMobile ? width : -1) }}>
        <Stack direction="row" display="flex" justifyContent="space-between">
          <Typography variant="h2">{title}</Typography>
          <IconButtonStyled onClick={closeModal}>
            <CloseIcon />
          </IconButtonStyled>
        </Stack>
        <BoxStyled>{children}</BoxStyled>
      </Box>
    </Modal>
  );
};

export default BaseModal;
