import { LanguageDesignatorWithRegion } from 'types/enums';

/**
 * Truncates a number to a specified number of decimal places
 *
 * @param num - The number to truncate.
 * @param fractionDigits - The number of decimal places to keep.
 * @returns The truncated number.
 */
const truncateNumber = (num: number, fractionDigits: number) => {
  const multiplier = Math.pow(10, fractionDigits);
  return Math.floor(num * multiplier) / multiplier;
};

/**
 * Creates a number formatter function.
 *
 * @param fractionDigits - The number of digits after the decimal point.
 * @returns A function that takes a number and returns it formatted as a string.
 */
const createNumberFormatter = (fractionDigits?: number) => {
  return (num: number) => {
    let truncatedNum = num;

    // If fractionDigits is provided, truncate the number to that many decimal places.
    if (typeof fractionDigits === 'number') {
      truncatedNum = truncateNumber(num, fractionDigits);
    }

    // Get the decimal part of the number.
    const decimalPart = `${num}`.split('.')[1];
    const decimalLength = decimalPart ? decimalPart.length : 0;

    // Return the number formatted according to the English (US) locale, with the number of decimal places
    // determined by fractionDigits (if provided) or the length of the decimal part of the number.
    return new Intl.NumberFormat(LanguageDesignatorWithRegion.EnglishUS, {
      style: 'decimal',
      minimumFractionDigits: typeof fractionDigits === 'number' ? fractionDigits : decimalLength,
      maximumFractionDigits: typeof fractionDigits === 'number' ? fractionDigits : decimalLength
    }).format(truncatedNum);
  };
};

const baseFormatter = createNumberFormatter();
const formatterWithZeroDecimals = createNumberFormatter(0);
const formatterWithTwoDecimals = createNumberFormatter(2);
const formatterWithThreeDecimals = createNumberFormatter(3);

const numberFormatter = {
  baseFormatter,
  formatterWithZeroDecimals,
  formatterWithTwoDecimals,
  formatterWithThreeDecimals
};

export default numberFormatter;
