import React, { useEffect, useState } from "react";
import { DateFormat, DisplayType } from "types/enums";
import Content from "../Content";
import Header from "../Header";
import SubContent from "../SubContent";
import SubHeader from "../SubHeader";
import DateFormatterProps from "./DateFormatter.props";

const DateFormatter = ({
  date,
  displayType = DisplayType.CONTENT,
  format = DateFormat.MMDDYYYY,
}: DateFormatterProps): React.ReactElement => {
  const [displayDate, setDisplayDate] = useState<string>("");

  useEffect(() => {
    let tempDate = typeof date === "string" ? new Date(date) : date;
    const month = tempDate.getMonth();
    const day = tempDate.getDate();
    const year = tempDate.getFullYear();

    switch (format) {
      case DateFormat.MMDDYYYY:
        setDisplayDate(`${month + 1}/${day}/${year}`);
        return;
      default:
        setDisplayDate(`${month + 1}/${day}/${year}`);
    }
  }, [date, format]);

  switch (displayType) {
    case DisplayType.CONTENT:
      return <Content>{displayDate}</Content>;
    case DisplayType.SUB_CONTENT:
      return <SubContent>{displayDate}</SubContent>;
    case DisplayType.HEADER:
      return <Header>{displayDate}</Header>;
    case DisplayType.SUB_HEADER:
      return <SubHeader>{displayDate}</SubHeader>;
    default:
      return <Content>{displayDate}</Content>;
  }
};

export default DateFormatter;
