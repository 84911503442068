import { Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { BaseQueryParams, CompanyDTO } from "@piefi-platform/types-lib";
import CompanyCard from "components/cards/company-card/CompanyCard";
import InputForm from "components/input-form/InputForm";
import COMPANY_SEARCH from "constants/company-search.labels";
import DASHBOARD_LABEL from "constants/dashboard.labels";
import { ROUTES } from "constants/routes";
import { useCompanyService } from "hooks";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

const CompanyListFilter = (): React.ReactElement => {
  const [companies, setCompanies] = useState<CompanyDTO[]>([]);
  const [params, setParams] = useState<BaseQueryParams>({ page: 0, size: 16 });
  const { getCompanies } = useCompanyService();
  const { SEARCH_PROMPT } = DASHBOARD_LABEL;
  const { COMPANY_NAME } = COMPANY_SEARCH;
  const navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      const results = await getCompanies(params);
      setCompanies(results.data);
    };

    fetch();
  }, [params, getCompanies]);

  return (
    <Stack>
      <Typography variant={"h5"} paddingBottom={"1rem"}>
        {SEARCH_PROMPT}
      </Typography>
      <InputForm
        onChange={(e) => setParams({ ...params, phrase: e.target.value })}
        placeholder={COMPANY_NAME}
        style={{ marginBottom: 2 }}
      />
      <Stack marginTop={"1rem"}>
        <Grid
          container
          spacing={2}
          style={{ width: "100%", margin: 0, justifyContent: "space-between" }}
        >
          {companies?.map((company) => (
            <Grid item md={3} sm={6} xs={12} key={company.id}>
              <CompanyCard
                title={company.name}
                subtitle={company.id.slice(-4)}
                imageUrl={company.dao?.logoThumbnail}
                onClick={() => navigate(ROUTES.COMPANY_ALLOCATION_LIST(company.id))}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
};

export default CompanyListFilter;
