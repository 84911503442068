const NOTIFICATIONS = {
  DAO_ID_MISSING: "DAO ID not provided",
  TRANSACTION_COPIED: 'TransactionId Copied',
  ERROR: 'Error occurred obtaining allocation list',
};

const BUTTONS = {
  CREATE: "Create Company Allocation",
  UPDATE: "Update Company Allocation",
  COPY_TRANSACTION_ID: 'Copy TransactionId'
};

const LABELS = {
  EQUITY_ALLOCATION_COUNT: "Equity Allocation Count:",
  VALUE_ALLOCATED: `Shares Allocated:`,
  IS_MINTED: (isMinted: boolean) => `Is Minted: ${isMinted.toString()}`,
  TOKENS_MINTED: (tokenCount: number) => `Tokens Minted: ${tokenCount}`,
  CREATE_CONFIRMATION: `Are you sure you would like to Allocate Equity? This action is NOT reversible.`,
  UPDATE_CONFIRMATION: `Are you sure you would like to update the  Company Allocation? This will update all equity allocations attached.`,
  BITS: 'Bits'
};

const FORM = {
  CREATE: "Create Company Allocation",
  UPDATE: "Update Company Allocation",
  CREATE_SUCCESS: "Allocation Successful Created",
  UPDATE_SUCCESS: "Allocation Successful Update",
  VALUE_ALLOCATED: "Shares Allocated"
};

const EQUITY_ALLOCATION = {
  NOTIFICATIONS,
  BUTTONS,
  LABELS,
  FORM,
};

export default EQUITY_ALLOCATION;
