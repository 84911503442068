import { AppBar, Box, Button, Container, Grid, Toolbar } from "@mui/material";
import { Stack } from "@mui/system";
import { UpsideDaoButton } from "components";
import { APP_VERSION } from "constants/app-config";
import { LOGIN } from "constants/auth.labels";
import { ROUTES } from "constants/routes";
import { useAuth } from "hooks";
import { ReactElement, useEffect } from "react";
import { Outlet, useNavigate } from "react-router";

const Header = (): ReactElement => {
  const { logOut } = useAuth();
  useEffect(() => {
    console.log(`App Version: ${APP_VERSION}`);
  }, []);
  const navigate = useNavigate();
  const { LOGOUT } = LOGIN;

  return (
    <Container maxWidth="lg" disableGutters>
      <Grid container spacing={0} justifyContent="center">
        <Grid item md={10} xs={12}>
          <AppBar
            color="inherit"
            sx={{
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.1), 0px 4px 5px 0px rgba(0,0,0,0.07), 0px 1px 10px 0px rgba(0,0,0,0.06)",
            }}
          >
            <Toolbar disableGutters style={{ marginLeft: 25 }}>
              <Stack
                alignItems="center"
                justifyContent="space-between"
                sx={{ flexGrow: 1 }}
                direction="row"
              >
                <Box>
                  <Stack
                    alignItems="center"
                    sx={{ flexGrow: 1 }}
                    direction="row"
                  >
                    <UpsideDaoButton
                      onClick={() => navigate(ROUTES.DASHBOARD)}
                    />
                  </Stack>
                </Box>
                <Box style={{ marginRight: 15 }}>
                  <Stack justifyContent={"flex-end"}>
                    <Button variant={"contained"} onClick={() => logOut()}>
                      {LOGOUT}
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            </Toolbar>
          </AppBar>
          <Box
            sx={{
              marginTop: {
                xs: 8,
                md: 10,
              },
            }}
          >
            <Outlet />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Header;
