import { styled } from "@mui/material";
import UpsideDaoIcon from "assets/icons/UpsideDaoIcon";

const UpsideDaoButton = styled(UpsideDaoIcon)(() => ({
  '&:hover': {
    cursor: 'pointer',
  },
}));

export default UpsideDaoButton;
