const TAB_LABEL = {
  COMPANY_ASSOCIATION: 'Company Association',
  GENERAL_INFO: 'General Information',
  EQUITY_ALLOCATION: "Equity Allocation",
  SEASONS: 'Seasons',
  TEMP_INVITATIONS: 'Temp Invitations',
  DOCUMENTS: 'Documents',
  SEED_DATA: 'Seed Data',
};

const PAGE_HEADER = {
  DAO: "Collective",
};

const POINT_LABEL = {
};

const TOKEN_DISTRIBUTION_LABEL = {
};

const METADATA_LABEL = {
  ABOUT: "About",
  MEMBERS: "Members: ",
  RULES: "Rules",
  STATE: "State: ",
};

const BUTTON_LABEL = {
  ACTIVATE_DOMAIN: 'Activate Domain',
  VIEW_COMPANY: 'View Company',
  UPDATE_DAO: 'Update Collective',
};

const NOTIFICATIONS = {
  ID_MISSING: "DAO ID not provided",
  NOT_FOUND: "Collective not found",
};

const DAO_VIEW_LABELS = {
  TAB_LABEL,
  POINT_LABEL,
  TOKEN_DISTRIBUTION_LABEL,
  METADATA_LABEL,
  BUTTON_LABEL,
  NOTIFICATIONS,
  PAGE_HEADER,
};

export default DAO_VIEW_LABELS;
