/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 * Builds a query string from an object.
 *
 * @param query - The object to convert into a query string.
 * @returns The query string. If the object is empty, returns an empty string.
 *
 * The function supports arrays. If an array is passed in, the string is generated as:
 * arrayName[]=item1&arrayName[]=item2&arrayName[]=item3
 *
 * For non-array values, the string is generated as: key=value
 */
const generateQueryString = (query: any): string => {
  if (Object.keys(query).length === 0) return '';

  Object.keys(query).forEach((key) => query[key] === undefined && delete query[key]);

  return Object.keys(query)
    .flatMap((queryKey: string) => {
      const operator = '=';
      const val = query[queryKey as keyof any];
      if (Array.isArray(val)) {
        return val.map((item) => `${queryKey}[]${operator}${item}`);
      } else {
        return `${queryKey}${operator}${val as string}`;
      }
    })
    .join('&');
};

export default generateQueryString;
