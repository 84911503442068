import { Button, Stack, Typography } from "@mui/material";
import VALUATION_VIEW_LABELS from "constants/valuation-view-labels";
import { FirebaseError } from "firebase/app";
import { useNotification, useValuationService } from "hooks";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Severity } from "types/enums";
import FirebaseAuthError, {
  formatFirebaseError,
} from "types/enums/firebase-auth-error.enum";
import { ConfirmationModal } from "../../modals";
import InputForm from "../InputForm";
import ValuationCreateFormProps from "./valuation-create-form.props";

const ValuationCreateForm = ({ onCreate }: ValuationCreateFormProps) => {
  const [loading, setLoading] = useState(false);
  const { createValuation } = useValuationService();
  const { companyId } = useParams();
  const { control, getValues } = useForm<{ sharePrice: number }>({
    mode: "onSubmit",
    shouldFocusError: true,
    reValidateMode: "onChange",
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { notify } = useNotification();

  const { LABELS } = VALUATION_VIEW_LABELS;

  const submit = async () => {
    const valuation = getValues();

    setLoading(true);
    try {
      if (!companyId) return;

      const sharePrice = parseFloat(
        `${valuation.sharePrice}`.replace(/,/g, "")
      );
      const result = await createValuation(companyId, { sharePrice });

      onCreate(result.data);
      notify(VALUATION_VIEW_LABELS.NOTIFICATIONS.SUCCESSFULLY_CREATED);
    } catch (error) {
      notify(
        formatFirebaseError(
          (error as FirebaseError)?.code as FirebaseAuthError
        ),
        {
          severity: Severity.Error,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form data-testid="valuation-form">
        <Stack spacing={3} paddingTop={"2rem"}>
          <Typography variant={"h4"}>
            {LABELS.CREATE_VALUATION_EVENT}
          </Typography>
          <Controller
            control={control}
            name="sharePrice"
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              const handleNumberChange = (
                e: React.ChangeEvent<HTMLInputElement>
              ) => {
                const value = e.target.value;

                // Remove any commas from the value
                const cleanedValue = value.replace(/,/g, "");

                // Check if the value is a valid number
                const isValidNumber =
                  !isNaN(parseFloat(cleanedValue)) || cleanedValue === ".";

                if (isValidNumber) {
                  let formattedValueWithoutDecimal;

                  if (cleanedValue.startsWith(".")) {
                    // If value starts with a decimal, prepend a "0"
                    formattedValueWithoutDecimal = Intl.NumberFormat(
                      "en-US"
                    ).format(parseFloat("0" + cleanedValue));
                  } else {
                    // Otherwise, format as usual
                    formattedValueWithoutDecimal = Intl.NumberFormat(
                      "en-US"
                    ).format(parseFloat(cleanedValue));
                  }

                  // Check if user's value ends with a decimal and append it if needed
                  const formattedValue = cleanedValue.endsWith(".")
                    ? formattedValueWithoutDecimal + "."
                    : formattedValueWithoutDecimal;

                  field.onChange(formattedValue);
                } else if (!value) {
                  field.onChange("");
                }
              };

              return (
                <InputForm
                  id={field.name}
                  data-testid={field.name}
                  {...field}
                  autoFocus
                  fullWidth
                  hiddenLabel
                  inputProps={{ maxLength: 128 }}
                  error={!!fieldState.error}
                  placeholder="0"
                  label={LABELS.TOTAL_VALUE}
                  onChange={handleNumberChange}
                  size="small"
                  type="text"
                  variant="outlined"
                  helperText={fieldState.error?.message}
                />
              );
            }}
          />
        </Stack>
        <Button
          variant="contained"
          type="button"
          onClick={() => setShowConfirmation(!showConfirmation)}
          disabled={loading}
          sx={{ marginTop: "2rem" }}
        >
          {LABELS.CREATE_VALUATION_EVENT}
        </Button>
      </form>
      <ConfirmationModal
        onCancel={() => {
          setShowConfirmation(false);
        }}
        onConfirm={() => {
          submit();
          setShowConfirmation(false);
        }}
        open={showConfirmation}
        subtitle={LABELS.ARE_YOU_SURE}
      />
    </>
  );
};

export default ValuationCreateForm;
