import { IconButton, Link, Stack, Typography } from "@mui/material";
import CopyIcon from "assets/icons/CopyIcon";
import FlagIcon from "assets/icons/FlagIcon";
import { AvatarBuilder } from "components";
import { BASE_URL, UPSIDE_PREFIX_URL } from "constants/app-urls";
import { CLIPBOARD } from "constants/utils.labels";
import { useNotification } from "hooks";
import { AvatarSize } from "types/enums";
import { copyToClipboard, formatDateLong } from "utils";
import DaoHeaderProps from "./DaoHeader.props";
import {
  CompanyNameStyled,
  ProfileContainerStyled,
  StyledHeaderStack,
  StyledInfoStack,
  SubStackStyled,
} from "./DaoHeader.style";

const DaoHeader = ({ dao }: DaoHeaderProps): React.ReactElement => {
  const { notify } = useNotification();
  const { COPIED } = CLIPBOARD;

  return (
    <ProfileContainerStyled data-testid="dao-header" tabIndex={0}>
      <StyledHeaderStack>
        <AvatarBuilder
          src={dao.logoThumbnail}
          id={"profile-avatar"}
          alt={dao.name}
          size={AvatarSize.X_LARGE}
        />
        <StyledInfoStack id="profile-info-segment">
          <CompanyNameStyled data-testid="userName" variant="h1">
            {`Collective: ${dao.name}`}
          </CompanyNameStyled>
          <SubStackStyled direction={"column"} marginTop="0.5rem">
            <Stack direction={"row"} alignItems="center">
              {dao.id !== undefined && (
                <>
                  <IconButton
                    onClick={() => {
                      copyToClipboard(dao.id);
                      notify(COPIED);
                    }}
                  >
                    <CopyIcon />
                  </IconButton>
                  <Stack direction="row" alignItems="center">
                    <Typography variant="body2" color={"secondary.main"}>
                      {dao.id}
                    </Typography>
                  </Stack>
                </>
              )}
              {dao.createdAt && (
                <Stack direction="row" alignItems="center" paddingLeft={"1rem"}>
                  <FlagIcon />
                  <Typography
                    variant="body2"
                    paddingLeft="0.25rem"
                    color={"secondary.main"}
                  >
                    {formatDateLong(new Date(dao.createdAt).toDateString())}
                  </Typography>
                </Stack>
              )}
            </Stack>
            {dao.whiteLabelSubDomain && (
              <Link
                style={{ textDecoration: "none", paddingLeft: "0.5rem" }}
                href={`https://${UPSIDE_PREFIX_URL()}.${BASE_URL}/c/${
                  dao.whiteLabelSubDomain
                }`}
                target="_blank"
                rel="noreferrer"
              >
                {`https://${UPSIDE_PREFIX_URL()}.${BASE_URL}/c/${
                  dao.whiteLabelSubDomain
                }`}
              </Link>
            )}
          </SubStackStyled>
        </StyledInfoStack>
      </StyledHeaderStack>
    </ProfileContainerStyled>
  );
};

export default DaoHeader;
